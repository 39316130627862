.physician-select-option-container {
    border: 3px solid  rgba(76, 35, 181, 0.05);
    display: flex;
    justify-content: start;
    height: 50px;
    align-items: center;
}
.outlined-custom-input-container{
    width: 100% !important;
}

.physician-select-option-container :hover {
  background-color: rgba(76, 35, 181, 0.05);
}

.physician-select-option-checkbox {
    position: relative;
    width: 1.4em;
    margin-left:16px;
    height: 1.4em;
    color: #363839;
    border: 3px solid  #4c23b5 ;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
.css-1rhbuit-multiValue {
    background-color: rgba(76, 35, 181, 0.1) !important;
    border-radius: 20px !important;
    display: -webkit-box;
}

.physician-select-option-checkbox::before {
    position: absolute;
    content: '';
    display: block;
    top: 1px;
    left: 2px;
    width: 0.8em;
    height:0.85em;
    border-style: solid;
    border-radius: 1px;
    border-color: #4c23b5;
    border-width: 3px;
    background-color: #4c23b5;
    /*transform: rotate(45deg);*/
    opacity: 0;
}
.physician-select-option-checkbox:checked {
    color: #fff;
    border-color: #4c23b5;
    background: white;
}
.physician-select-option-checkbox:checked::before {
    opacity: 1;
}



.physician-select-option-label {
    font-family: Rubik-Regular;
    font-size: 18px;
    line-height: 1.22;
    text-align: left;
    color: #000000;
    margin-left: 16px;
}

.outlined-custom-input-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 16px;
}
.outlined-custom-input-labelPhone-active {
    z-index: 1000;
    top: -5px;
    transform: translateY(-50%);
    font-size: 16px;
}

.css-1da7yph-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-radius: 5px;
    /*box-shadow: inset 2px 2px 1px 1px rgb(199 197 197);*/
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: unset;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 1);
    text-transform: capitalize;
    font-size: 17px;
    padding: 2px 3px 2px 3px;
}

