.main-container {
    width: 100%;
    padding:0 1rem 1rem 1rem;
    height: 100%;

}

.main-container-sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 0.25rem;
    align-items: center;

}
.stickMain{
    position: sticky;
    width: 45.5%;
    height: 0.75rem;
    background-color: #F5F5F7;
    z-index: 1;
}

.main-date {
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;
    padding-top: 0.75rem;
    /*padding-bottom: 2rem;*/
    width: 100%;
    text-align: left;
    color: #2E2E2E;
}

.main-card {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 0.625rem;

}

.main-con {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.main-con-medium {
    width: 98% !important;
    overflow-x: hidden;
}
.main-con-large{
    width: 96% !important;
    overflow-x: hidden;
}
.main-con-extraLarge{
    width: 72% !important;
}
.main-con-extraSmall{
    width: 90% !important;
}
.main-con-tablet {
    width: 99% !important;
    overflow-x: hidden;
}

.main-con-description {
    width: 100%;
    display: table;
    max-height: 24.1875rem;
    min-height: 6.6875rem;

}

.main-con-description-medium {
    display: table;
    /*width: min-content;*/
    height: auto;
    max-height: fit-content !important;
}

.main-con-title-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.main-con-title {
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    text-transform: uppercase;
    color: #2E2E2E;

}

.main-con-date-time {
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: right;
    color: #A7A7A7;
    line-height: 0.75rem;
    min-width: max-content;
}

.main-con-description-body {
    display: grid;
    grid-template-columns: auto auto;

}

.main-con-description-body-medium {
    grid-template-columns: auto !important;
}

.main-con-video-div {
    width: 20rem;
    height: 20rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.625rem;
    margin-bottom: 1rem;
}
.main-video-enlarge-button{
    width: 1rem;
    height: 1rem;
    position: relative;
    bottom: 20.3%;
    left: 90.6%;
}
.main-con-video {
    width: 100%;
    height: 100%;
    border-radius: 0.625rem;

    /* Inside auto layout */
    object-fit: cover;
    /*object-position:center;*/

    /*flex: none;*/
    /*order: 0;*/
    /*flex-grow: 0;*/

}
.description-title{
    color:  #2E2E2E;
    font-size: 1rem;
    font-family: Rubik-SemiBold;
    font-style: normal;
    font-weight: 500;
}
.description-value{
    color:#2E2E2E;
    font-size: 1rem;
    font-family: Rubik-Light;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
}
.main-con-description-text {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    max-height: 20.1875rem;
    max-width: 94%;
    overflow-y: auto;
    word-wrap: break-word;
}
.main-con-no-description{
    font-family: 'Rubik-Regular';
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    color: #CBCBCB;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.main-con-description-text::-webkit-scrollbar{
    width: 0 !important;
}
.main-con-description-text-medium {
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.main-top-exams {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;

}

.main-bottom-exams {
    width: 100%;
    margin-top: 1rem;

}

.main-top-exams-mobile {
    display: grid;
    margin-top: 1rem;
}

.main-top-exams-small {

}

.main-top-exams-exam-card {
    padding-top: 1rem;
    padding-left: 1.4375rem;
    height: 9.5rem;
    flex-grow: 1;
    flex-basis: 0;
    margin-top: 0.5rem;
    /* width: 33.33%; */
    /* margin-right: 0.5rem; */
    padding-bottom: 0.5rem;
}

.main-bottom-exams-exam-card {
    padding: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    min-height: 8rem;
    flex-grow: 1;
    flex-basis: 0;

}

.main-top-exams-title-container {
    display: flex;
    width: 75%;
    align-items: center;
}
.main-top-exams-exam-first-row{
    height: 2.5rem;
}
.main-bottom-exams-title-container {
    display: flex;
    align-items: center;
}

.main-top-exams-icon {
    width: 2.25rem;
}

.main-top-exams-examTitle {
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    text-transform: uppercase;
    color: #2E2E2E;
    margin-left: 0.5rem;
    width: 48%;
}

.main-top-exams-exam-value {
    margin-top: 0.75rem;
    font-size: 1.75rem;
    color: #2E2E2E;
    font-family: Rubik-SemiBold;

}

.main-top-exams-exam-date {
    font-family: 'Rubik-Regular';
    font-weight: 400;
    font-size: 0.875rem;
    /*text-align: right;*/
    color: #A7A7A7;
    margin-top: 0.45rem;
    margin-bottom: 0.75rem;
    /*width: 100%;*/
}
.main-top-exams-exam-date-prescription{
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
}
.main-bottom-exams-exam-date{
    /*width: 38% !important;*/
    margin-right: 1rem;
    text-align: right;
}

.main-bottom-exams-exam-first-row {
    display: flex;
    align-items: center;
    /*align-content: center;*/
    height: 2rem;
    justify-content: space-between;
}

.bottom-exams-title {
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    /*line-height: 1rem;*/
    text-transform: uppercase;
    color: #2E2E2E;
    margin-left: 0.5rem;

}
.main-card main-bottom-exams-exam-card{
    height: fit-content;
}
.back-to-top-button{
    cursor: pointer;
    position: absolute;
    top: 90%;
    left: 63%;
}
.navigationButtons{
    display: flex;
}
.navigation-svg{
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    cursor: pointer;
}
.modal-background{
    background-color: rgba(94, 94, 94, 0.4);
    z-index: 99999 !important;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
.useHeadPhones{
    border-radius: 8px;
    border: 1px solid #F0EFF2;
    background:  #F0EFF2;
    color: #979797;
    display: flex;
    align-items: center;
    padding: 4px 12px 4px 8px;
    align-items: center;
    gap: 6px;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
}