.modal {
    background-color: rgba(94, 94, 94, 0.4);
    z-index: 9999 !important;
}

.media-header, .media-content {
    border: unset !important;
    background-color: transparent !important;
}

.image-media-content, .image-media-dialog {
    width: 530.8px;
    height: 617.3px;
}
.media-content-filtering{
    width: 100% !important;
}
.modal-image-media {
    background: #ff7b4a;
    border-radius: 18px;
    border: solid 2px #ff7b4a;
}

.modal-body {
    padding: 0 !important;
}

.modal-image-media {
    width: 530.8px;
    height: 617.3px;
}

.modal-close {
    background-color: rgba(76, 35, 181, 0.1);
    opacity: 1;
    height: 35px !important;
    width: 35px !important;
    z-index: 100000000;
    border-radius: 50px;
    position: absolute;
    outline: rgba(0, 0, 0, 0.05) !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-close-video{
    z-index: 100000000;
    position: absolute;
    display: flex;
    justify-content: end;
    top: -64px !important;
}

.media-close-btn-container {
    top: -45px;
    right: -45px;
}

.modal-close:hover {
    background: rgba(54, 36, 142, 0.3);
}

.modal-close-btn {
    height: 40%;
    width: 40%;
}
.modal-close-btn-video{

}
.modal-close-btn-snap{
    cursor: pointer;
    width: 15% !important;
    position: absolute;
    bottom: 80%;
    left: 90%;
}
.modal-missed-btn {
    height: 24px;
    width: 24px;
    margin-right: 15px;
}

.modal-close-btn path {
    height: 70%;
    width: 70%;
}
.video-media-content-filtering{
    width: 900px !important;
    height: 455px !important;

}
.image-body{
    text-align: center;
    width: 100% !important;
    height: 100% !important;
}
.video-media-content, .video-media-dialog {
    width: 659px;
    height: 652.5px;
}
.video-media-dialog-filtering{
    width: 930px !important;
    height: 830px !important;
    min-height: 830px !important;
    min-width: 930px !important;
    background: #000000;
    border-radius: 10px;
    padding: 30px 20px 10px;
    top: 6%;
}
.snap-dialog{
    height: 830px !important;
    min-height: 700px !important;
}
.vp-cont-modal-video-media {
    width: 659px;
    height: 652.5px;

}
.video-media-dialog-filtering .vp-cont-modal-video-media {
    width: 98% !important;

}



.vp-frame-modal-video-media {
    width: 659px;
    height: 582.5px;
    /*border: solid 2px rgba(76, 35, 181, 0.5);*/
}
.video-media-dialog-filtering .vp-frame-modal-video-media {
    width: 98% !important;
    height: 520px !important;
    position: relative;
    bottom: 3%;
}

.video-player-control.vp-cont-modal-video-media {
    width: 659px;
    height: 70px;
    /*border: solid 2px rgba(76, 35, 181, 0.5);*/
    background: #eee9f8;
    display: flex;
    align-items: center;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 0 10px !important;
}

.video-player-playing-control.vp-modal-video-media {

    height: 25px;
    width: 25px;
    margin-right: 15px;
}

.video-player-screen-control.video-fullscreen.vp-fsc-modal-video-media {
    margin-left: 15px;
    height: 25px;
    width: 25px;
}
.modal-media-filtering{
    width :829px !important;
    /*height: 455px !important;*/
}
input[type=range].modal-media::-webkit-slider-runnable-track {
    background-color: rgba(76, 35, 181, 0.3) !important;
}

/*.modal-video-media-filtering video::-webkit-media-controls-timeline {*/
/*    position: relative;*/
/*   top: 4% !important;*/
/*}*/
.modal-video-media-filtering video::-webkit-media-controls-panel
{
    position: relative;
    /*top:10% !important;*/
    width: 100%;
    z-index: 9999;
}
.modal-snap-media-filtering{
    width: 70%;
    max-height: 94%;
}
input[type=range].modal-media::-webkit-slider-thumb {
    background-color: #4c23b5 !important;
}

.vp-modal-video-media path {
    fill: #4c23b5;
}

.session-tab-Saturation {
    /*min-width: 250px !important;*/
}
.brightnessFilterContainer{
    display: flex;
    width: 100%;
    height: 58px;
    /*justify-content: space-evenly;*/
    gap: 10px;
    position: relative;
    flex-direction: row;
    bottom: 11%;

}
.snapShotContainer{
    width: 158px !important;
    justify-content: center;
    padding: 0px 10px;
    border: 1px solid transparent;
}
.snapShotContainer-mobile{
    width: 3.5rem !important;
    justify-content: center;
    border: 1px solid transparent;
    /*padding: 0px 10px;*/
}
.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}
#output{
    position:absolute;
    z-index: 9999;
    width: 500px;
    height: 500px;
}
.brightnessFilter{
    display: flex;
    flex-direction: row;
    align-items: center;
    /*padding: 0px 10px;*/
    /*justify-content: space-between;*/
    /*gap: 20px;*/
    width: 58%;
    height: 58px;
    background: #2E2E2E;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}
.frameFilter{
    width: 282px !important;
    padding: 4px !important;
}
.frameFilterWhite{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 117px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 32px;
    font-family: Rubik-Regular;
    color: #2E2E2E;
    text-align: center;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

}
.snapShotWhite{
    width:98%;
}
.snapShotWhite:active {
    position: relative;
    top: 2px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: All 250ms ease;
    -moz-transition: All 250ms ease;
    -o-transition: All 250ms ease;
    -ms-transition: All 250ms ease;
    transition: All 250ms ease;
}
.frameFilterWhite:active {
    position: relative;
    top: 2px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: All 250ms ease;
    -moz-transition: All 250ms ease;
    -o-transition: All 250ms ease;
    -ms-transition: All 250ms ease;
    transition: All 250ms ease;
}

.radio-brightness-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.radio-brightness-container{
    width: 100%;
    padding: 10px;
    text-align: center;


}
.modal-frame{
    position: relative;
    top: 0.4vh;
}
.lightIcon{
    display: inline;
    width: 22%;
}
.brightnessFilterButton{
    font-family:Rubik-Regular;
    color: #FFFFFF;
    font-size: 14px;
    text-align: center;
    width: 100%;
}
.brightnessFilterButtonSelected {

    overflow-wrap: break-word;
    border-radius: 32px;
    padding: 12px 13px;
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    height: 44px;
    width: 139px;
    font-size: 12px;
    justify-content: center;
}
.video-modal-title{
    color: white;
    position: relative;
    bottom: 10%;
    margin-left: 3%;
    font-size: 30px;
    font-family:Rubik-Regular;
}
.captures-length{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(76, 35, 181);
    color: white;
    position: absolute;
    left: 96%;
    bottom: 69%;
    z-index: 1;

}
