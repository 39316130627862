.patient-Container-app{
    width: 101.5vw;
    background-color:#E4E4E6;
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 100%;

}
.patientContainer{
    height: 100%;
    width: 100%;
    /*min-width: 100%;*/
    /*max-height: 100%;*/
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;


}
.patientContainerFull{
    height: 100%;
    width: 100%;
    /*min-width: 100%;*/
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    background-color:#F5F5F7;
}

.patientContainerNotes{
    display: grid;
    justify-content: center;
}

.menu { grid-area: menu; }
.main { grid-area: main; }
.note { grid-area: note; }


.grid-container {
    font-family: 'Rubik-Regular';
    width: 100%;
    display: flex;
    background-color: #F5F5F7;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding:0;
    justify-content: space-between;
}

/**************************Header*********************/
.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
    position: static;
    /*padding: 0px 1.5rem 0px 0px;*/
    width: 100%;
    height: 4.5rem;
    background: #FFFFFF;
    flex: none;
    order: 0;
    z-index: 1;
    align-self: stretch;
    flex-grow: 0;
    border-bottom: 1.5px solid #EEE9F8;
}
.headerSmall{
height: 9rem !important;
}
.mobileHeader{
    height: 14.5rem !important;
    /*position: unset;*/
}
.mobileHeaderScrolled{
    height: 19.5rem !important;
    /*position: unset;*/
}

/************************************* Main ***************************************************/
.main{
    padding: 0px;
    min-height: 70vh;
    height: 100%;
    overflow-y: auto;
    /*border: 1px solid #F0EFF2;*/
    width: 107rem;
    margin-bottom: 10rem;
    /*margin-bottom: 2rem;*/
    display: flex;
    justify-content: center;
}

.main-mobile{
    padding: 0px;
    /* min-height: 56%; */
    height: 100%;
    overflow-y: auto;
    border: 1px solid #F0EFF2;
    width: 107rem;
    margin-bottom: 10rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 5rem;

}
.main-tablet{
    /*height: 78%;*/
    height: 100%;
    padding: 0px;
    /*min-height: 70%;*/
    overflow-y: auto;
    border: 1px solid #F0EFF2;
    width: 107rem;
    margin-bottom: 10rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

/************************************* Notes ***************************************************/
.note{
    padding: 0px;
    min-height: 100vh;
    height: 100vh;
    background: #FFFFFF;
    overflow-y: auto;
    width: 17.5rem;
    min-width: 17.5rem;
    overflow-x: hidden;
    z-index: 2;

}

@media (min-width: 1440px){
    .patientContainer{
       width:90rem !important;
       max-width:90rem !important;
        overflow-y: hidden !important;
    }

}
/*@media (min-width: 1920px){*/
/*    .note {*/
/*        min-width: 22.5rem !important;*/
/*        width: 22.5rem !important;*/
/*    }*/
/*    .menu{*/
/*        min-width: 22.5rem !important;*/
/*        width: 22.5rem !important;*/
/*    }*/
/*    .main-container{*/
/*        min-width: 57.5rem !important;*/
/*        width: 57.5rem !important;*/
/*    }*/
/*    .main-con{*/
/*        min-width: 64.5rem !important;*/
/*        width: 64.5rem !important;*/
/*    }*/
/*}*/
/*@media (min-width: 1441px) and (max-width:1919px){*/
/*    .note {*/
/*        min-width: 17.5rem !important;*/
/*        width: 17.5rem !important;*/
/*    }*/

/*    .menu{*/
/*        min-width: 17.5rem !important;*/
/*        width: 17.5rem !important;*/
/*    }*/
/*    .main-container{*/
/*        min-width: 52.25rem !important;*/
/*        width: 52.25rem !important;*/
/*    }*/
/*    .main-con{*/
/*        min-width: 52.25rem !important;*/
/*        width: 78% !important;*/
/*    }*/
/*}*/
@media (min-width: 1070px) and (max-width:1439px){
    /*.note {*/
    /*    min-width: 17.5rem !important;*/
    /*    width: 17.5rem !important;*/
    /*}*/

    /*.main{*/
    /*    min-width: 60rem !important;*/
    /*    width:60rem !important;*/
    /*}*/
    .main-container{
        min-width: 38.75rem !important;
        width: 38.75rem !important;
    }
    .main-con{
        min-width: 38.75rem !important;
        width: 38.75rem !important;
    }
}
@media (min-width: 1025px) and (max-width:1070px){
    .main-container{
        min-width: 35.75rem !important;
        width: 35.75rem !important;
    }
    .main-con{
        min-width: 35.75rem !important;
        width: 35.75rem !important;
    }
}
@media (min-width: 1000px) and (max-width:1125px){
    /*.note {*/
    /*    min-width: 17.5rem !important;*/
    /*    width: 17.5rem !important;*/
    /*}*/

    /*.main{*/
    /*    min-width: 60rem !important;*/
    /*    width:60rem !important;*/
    /*}*/
    .main-container{
        min-width: 33rem !important;
        width: 33rem !important;
    }
    .main-con{
        min-width: 33rem !important;
        width: 33rem !important;
    }
}
@media (min-width: 768px) and (max-width:1125px){
    .past-exams-sub-container{
        width: 80%!important;
    }
}
@media (min-width: 1126px) and (max-width:1145px){
    .piiInfoDiv{
        font-size: 0.75rem !important;
    }
}

.note-xl{
    width: 22.5rem;
    min-width: 22.5rem;
}
.notes-mobile{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    animation-name: slide-in;
    animation-duration: 0.75s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
    overflow-x: hidden;
}
@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
.notes-tablet{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    animation-name: slide-in;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
    overflow-x: hidden;
}
.notes-mobile-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 5rem;
    height: 5rem;
    left: 75%;
    top: 85%;
    background: #FFFFFF;
    border: 0.0938rem solid #F0EFF2;
    box-shadow: 0px 3px 10px rgba(138, 140, 157, 0.349);
    border-radius: 50%;
}
.notes-mobile-button-icon{
    width: 1.5625rem;
    margin-bottom: 0.5rem;

}
/************************************* Menu ***************************************************/
.menu{
    padding: 0px;
    min-height: 100vh;
    height: 100vh;
    background-color: #F5F5F7;
    overflow-y: auto;
    /*min-width: 17.5rem;*/
    width: 17.5rem;
    min-width: 17.5rem;
    border-right: 1px solid #F0EFF2;
}
.menuTablet{
    width:100%;
    height: 4.5rem;
    display: flex;
    background-color: #F5F5F7;
    justify-content: center;
    align-items: center;

}
.menuMobile{
    margin-top: 0rem;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 2rem;
    position: fixed;
    z-index: 99;
}
.menuMobile::-webkit-scrollbar , .menu::-webkit-scrollbar, .main::-webkit-scrollbar, .main-mobile::-webkit-scrollbar,.main-con-tablet::-webkit-scrollbar, .note::-webkit-scrollbar,.notes-tablet::-webkit-scrollbar, .notes-mobile::-webkit-scrollbar, .main-tablet::-webkit-scrollbar, .past-exams-container::-webkit-scrollbar, .main-con::-webkit-scrollbar, .past-exams-sub-container::-webkit-scrollbar, .dtc-patient-profile::-webkit-scrollbar, .patientContainer::-webkit-scrollbar{
    width: 0px !important;
    height: 0px !important;
    display: none !important;
}
.menuMobile::-webkit-scrollbar-thumb , .menu::-webkit-scrollbar-thumb{
    background-color:transparent !important;
}
.modal-close-btn-menu{
    width :1.25rem;
    height:1.25rem;
    margin-right: 1rem;
}
.menuVersion{
    display: flex;
    height: 50%;
    width: 100%;
    align-items: end;
    padding-left: 2rem;
    color:  #A7A7A7;
    text-align: right;
    font-size: 0.875rem;
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: 400;
}
.call-back-to-exams{
    position: absolute;
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 24px 16px 16px 16px;
    align-items: flex-start;
    color: #FFF;
    gap: 10px;
    font-size: 1rem;
    height: 64px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);

}