::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #4c23b5;

    /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);*/
}

::-webkit-scrollbar-track-piece {
    background: #F2F5F9;
}

::-webkit-scrollbar-thumb {
    background: #4c23b5;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #4c23b5;
}
