.tooltip-custom {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip-custom .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: #fff;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
/*.tooltip-custom:hover .tooltiptext {*/
/*    visibility: visible;*/
/*}*/

.tooltip-custom-right .tooltiptext {
    top: -5px;
    left: 105%;
}
.tooltip-custom-right .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent  #fff;
}
.tooltip-custom-left .tooltiptext {
    top: -5px;
    right: 105%;
}
.tooltip-custom-left .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent  #fff transparent transparent;
}
.tooltip-custom-top .tooltiptext {

    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
.tooltip-custom-top .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  #fff transparent transparent transparent;

}
.tooltip-custom-bottom .tooltiptext {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
.tooltip-custom-bottom .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent  #fff transparent;
}