.mhd-text-input-container {
    position: relative;
    padding: 15px 0 0;
    margin-top: 15px;
}

.mhd-text-input {
    width: 100%;
    border: 0;

    outline: 0;
    font-size: 1.2rem;
    color: #2e2e2e;
    padding: 7px 0;
    background: transparent !important;
    transition: border-color 0.2s;
}

.mhd-text-input label {
    font-family: Rubik-Medium;
    position: absolute;
    top: -5px;
    display: block;
    transition: 0.2s;
    /*font-size: 1rem;*/
    color: #4c23b5 !important;
    font-size: 24px;
}

.mhd-text-input > div > input {
    background: transparent !important;
    /*border-bottom: 3px solid rgba(76, 35, 181,0.3);*/
    height: 30px !important;
}

/*#fd5a56*/
.MuiInput-underline.Mui-error:before {
    border-bottom: 3px solid rgba(253, 90, 86, 1) !important;
}

.MuiInput-underline.Mui-error:after {
    border-bottom: 3px solid rgba(253, 90, 86, 1) !important;
}

.MuiInput-underline:before {
    border-bottom: 3px solid rgba(76, 35, 181, 0.3) !important;
}

.MuiInput-underline:after {
    border-bottom: 3px solid rgba(76, 35, 181, 0.6) !important;

}

.mhd-text-input:placeholder-shown ~ .mhd-text-input-label {
    font-family: Rubik-Medium;
    color: #4c23b5;
    font-size: 1.3rem;
    cursor: text;
    top: 18px;
}

/*MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl*/
/*.MuiPopover-paper {*/
/*    top: 754px!important;*/
/*    left: 1309px!important;*/
/*}*/

.undrline-select-container {
    border-bottom: 3px solid rgba(76, 35, 181, 0.3) !important;
}



.undrline-select-container.Mui-error:before {
    border-bottom: 3px solid rgba(253, 90, 86, 1) !important;
}

.undrline-select-container.Mui-error:after {
    border-bottom: 3px solid rgba(253, 90, 86, 1) !important;
}
