.web-app-logged-in-user {
    height: 50px;
    min-width: 190px;
    width: max-content;
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
}
.web-app-logged-in-user.pushed-down{
    top: 90px!important;
}
.users-avatar {
    /*border: 1px solid black;*/
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-right: 10px;
}

