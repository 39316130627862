/*.mhd-checkbox-input{*/
/*    height: 1rem;*/
/*    width: 1rem;*/
/*}*/
/*.mhd-checkbox-input:checked{*/
/*    background-color: #4c23b5;*/
/*}*/


.mhd-checkbox-input-container {
    /*width: 100%;*/
    position: relative;
    display: block;
}
.mhd-checkbox-input-container-report{
    margin-left: 10px;
    margin-right: 10px ;
}

.mhd-checkbox-input-container .mhd-checkbox-input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
}

.mhd-checkbox-input-container .mhd-checkbox-input-label {
    position: relative;
}

.mhd-checkbox-input-container .mhd-checkbox-input-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 1px;
    width: 1rem;
    height: 1rem;
    transition: transform 0.28s ease;
    border-radius: 3px;

}

.mhd-checkbox-input-container .mhd-checkbox-input-label:after {
    content: '';
    display: block;
    width: 10px;
    height: 5px;
    margin-top: -6px;
    margin-left: -7px;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    -webkit-transform: rotate(-55deg) scale(0);
    transform: rotate(-55deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;

    position: absolute;
    top: 12px;
    left: 10px;
}

.mhd-checkbox-input-container .mhd-checkbox-input:checked ~ .mhd-checkbox-input-label::before {
    background-color: #4c23b5;
    color: #4c23b5;
    border: 2px solid rgba(76, 35, 181, 1);
}
.mhd-checkbox-input-container .mhd-checkbox-input-report:checked ~ .mhd-checkbox-input-label::before {
    background-color: #a590d9;
    color: #a590d9;
    border: 2px solid #a590d9;
}

.mhd-checkbox-input-container .mhd-checkbox-input ~ .mhd-checkbox-input-label::before {
    background-color: #ffffff;
    border: 2px solid rgba(76, 35, 181, 0.7);
}

.mhd-checkbox-input-container .mhd-checkbox-input:checked ~ .mhd-checkbox-input-label::after {
    -webkit-transform: rotate(-55deg) scale(1);
    transform: rotate(-55deg) scale(1);
}

.mhd-checkbox-input-container .mhd-checkbox-input-label {
    min-height: 34px;
    display: block;
    padding-left: calc(1rem + 8px);
    font-weight: normal;
    cursor: pointer;
    vertical-align: sub;
}

.mhd-checkbox-input-container .mhd-checkbox-input-label span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mhd-checkbox-input-container .mhd-checkbox-input:focus + .mhd-checkbox-input-label::before {
    outline: 0;
}
.mhd-checkbox-input-label-report{
    font-family: Rubik-Regular !important;
    font-size: 14px !important;
    color: #2e2e2e !important;
}
