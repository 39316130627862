/*@media (min-width:375px)  { !* smartphones, iPhone, portrait 480x320 phones *! }*/
/*@media (min-width:769px)  { !* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. *! }*/
/*@media (min-width:1024px)  { !* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones *! }*/
/*@media (min-width:1281px) { !* hi-res laptops and desktops *! }*/

.headerContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;

}
.headerContainerSmallLine2{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding-top: 0.5rem;
    position: sticky;
    z-index: -1;
}
.headerContainerMobileLine2{
flex-direction: column;
    margin-top: 5rem;
    height: 8rem;
}
.headerContainerSmallScreen{
    width: 100%;
    height: 100%;
    z-index: 9998!important;
}
.smallScreenNameCont{
    display: flex;
    border-bottom: 1.5px solid #EEE9F8;
    height: 4.5rem;
    justify-content: space-between;
}
.smallScreenNameCont-left-section{
    display: flex;
}

.mobileNameCont{
    position: fixed;
    width: 100%;
    background: #FFFFFF;
}
.mobile-ellipsis{
    display: flex;
    align-self: center;
    width: 30%;
    margin-left: 10%;
    height: 100%;
    justify-content: end;
}
.tablet-ellipsis{
    display: flex;
    align-self: center;
    width: 62%;
    margin-left: 10%;
    height: 100%;
    justify-content: end;
}
.ellipsis-svg-mobile{
    transform: rotate(180deg);
    margin-right: 1rem;
    margin-top:-14%;
    cursor: pointer;
}
.share-svg-mobile{
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: -0.45rem;
    cursor: pointer;
}
.logoBox{
    border-right: 1.5px solid #EEE9F8;
    height: 100%;
    /*width:2.5rem;*/
    /*padding-right: 0.9875rem;*/
}
.piiInfoDiv{
    width:auto;
    max-width: 7.975rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 1rem;
    margin-right: 2.5rem;
    font-size: 0.875rem;
    /*line-height: 0.75rem;*/
}
._piiInfoDivSmall{
    /*border-right: 1px solid #EEE9F8;*/
    margin-right: 0 !important;
    border-right: 1px solid #EEE9F8;
    padding-right: 2rem;
    /*padding-left: 0.75rem;*/
    font-size: 0.875rem;
    max-width: 8.5rem !important;
    margin-left: 0 !important;

}
._piiInfoDivSmall-tablet{
    /* border-right: 1px solid #EEE9F8; */
    margin-right: 0 !important;
    border-right: 1px solid #EEE9F8;
    /* border: 1px solid; */
    /* padding-right: 2rem; */
    /* padding-left: 0.75rem; */
    font-size: 0.875rem;
    width: 10rem;
    padding-left: 1rem;
    /* max-width: 8.5rem !important; */
    margin-left: 0 !important;
}
.headerContainerSmallLine2 ._piiInfoDivSmall:first-child{
    margin-left: 1rem !important;

}
.headerContainerSmallLine2 ._piiInfoDivSmall:last-child{
    border-right: 0px solid #EEE9F8;

}
.piiInfoDivSmall{

    display: flex;
    width: auto;

    justify-content: space-between;
    padding-left: 1rem;
    width: max-content;

}
.header-mobile-middle{
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.piiInfoValueSmall{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.smallNameImage{
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 80vw;

}
.piiInfoLabel{

    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: #7F7F7F;
    flex: none;
    order: 0;
    flex-grow: 0;
    /*margin-bottom: 0.5rem;*/
}
.piiInfoValue{
    font-family: Rubik-SemiBold;
    font-style: normal;
    font-weight: 500;
    color: #2E2E2E;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.125rem !important;
}
.piiInfoDivImage{

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 1rem;
}
.piiInfoDivImageSmall{
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.patientImage{

    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
}
.header-ellipsis{
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 1.5rem;
}
.header-pii{
   display: flex;
}
.header-menu{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*padding: 0px 16px;*/
    position: relative;
    width: 13.5rem;
    min-height: 6.25rem;
    height: auto;
    /* align-self: flex-start; */
    top: 100%;
    /*left: 15%;*/
    /* bottom: 1rem; */
    /* right: 85%; */
    z-index: 9999;
    background: #FFFFFF;
    border: 1px solid #EEE9F8;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.50);
    border-radius: 4px;
}
.header-menu-mobile{
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    height: 100%!important;
    top: 0 !important;
    width: 17.5rem !important;
}
.header-menu-tablet{
    top: -56% !important;
    left: 77% !important;
}
.header-menu-label-mobile{
    /*margin-left: 1rem;*/
}
.header-menu-label{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    /* identical to box height, or 100% */
    /*margin-bottom: 1rem;*/
    /*margin-top: 1rem;*/
    width: 100%;
    height: 4.5rem;

    display: flex;
    align-items: center;

    /* main/main */

    color: #2E2E2E;
    cursor: pointer;
}
.header-menu-physician{
    color:  #A7A7A7;
    font-family: Rubik-SemiBold;
    font-size: 14px;
    font-style: normal;
    line-height: 26px; /* 185.714% */
}
.menu-title-mobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Rubik-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: #2E2E2E;
    width: 100%;
    /*margin-top: 1.5rem;*/
    margin-bottom: 1rem;
    height: 4.45rem;
    border-bottom: 1.5px solid #EEE9F8;
}