.tokbox-video-container {
    height: 100%;
    width: 100%;
}

.app-top-container {
    width: calc(100% - 85px);
}

.app-top-container-small {
    width: calc(90% - 385px);
    min-width: 845px;
}
.tokbox-chat-container-mobile{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
}
.tokbox-chat-container-mobile-small{
    width: auto;
    height: auto;
    /*position: absolute;*/
    z-index: 9999;
}

.tokbox-chat-container {
    width: 15.5rem;
    /*max-width: 429.5px;*/
    /*min-width: 381.7px;*/
    /*max-height: calc(1.8 * 429.5px);*/
    /*min-height: calc(1.8 * 420.7px);*/
    height: 29.875rem;
    padding-top: 3%;
    margin-top: 80px;
    margin-left: 51px;
}
.tokbox-chat-container-web{
    width: 15.5rem;
    position: absolute;
    top: 9rem;
    left: 14.2%;
    z-index:1111;

    /*max-width: 429.5px;*/
    /*min-width: 381.7px;*/
    /*max-height: calc(1.8 * 429.5px);*/
    /*min-height: calc(1.8 * 420.7px);*/
    height: 29.875rem;
}

.tokbox {
    height: 89%;
    position: relative;
    border-radius: 20px !important;
    width: 100%;
    box-shadow: 0px 0px 22px -3px rgba(0, 0, 0, 0.75);
}
.tokbox-mobile{
    height: 100%;
    width: 100%;
}
.tokbox-web{
    height: 100%;
}

.chat-timer {
    color: #30352f;
    font-size: 25px;
    font-family: Rubik-Light;
    width: 100%;
    position: absolute;
    text-align: center;
    top: -49px;
}

.OTSubscriberContainer, .patient-video-container > div {
    width: 100% !important;
    height: 90% !important;
}

.video-OTStreams, #video-OTStreams div {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;

    border: unset !important;
    background-color: rgba(89, 89, 89, 0.2);
}
.video-OTStreams-mobile{
    width: 100% !important;
    height: 100% !important;
    border: unset !important;
    background-color: rgba(89, 89, 89, 0.2);
}
.tokbox-mobile-small{
    width: 70px;
    height: 120px;
    position: absolute;
    right: 20px;
    top: 80px;
}
/*old*/
.tokbox-video-container > div {
    height: 100%;
    width: 100%;
    position: relative;
}

.tokbox-video-container > div > #video-OTStreams {
    height: 100%;
    width: 100%;
}

.tokbox-video-container > div > #video-OTStreams > div {
    height: 100%;
    width: 100%;
}

.video-OTStreams div {
    height: 100%;
    width: 100%;
}

.video-OTSubscriber {
    height: 100%;
    width: 100%;
}
.video-OTSubscriber-mobile{
    height: 100%;
    width: 100%;
}
.video-OTSubscriber > div {
    height: 100%;
    width: 100%;
}
.video-OTSubscriber-mobile > div {
    height:100%;
    width: 100%;
}

.online-session-placeholder {
    border-radius: 20px!important;
    background-color: #4D3687;
}
.online-session-placeholder-mobile{
    background-color: #4D3687;
    display: flex;
    flex-direction: column;
    align-items: center;

}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
    position: absolute !important;
    left: -9814px ;
    top: 62% ;
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
    box-shadow: 9970px 0 0 0 #ffffff, 9994px 0 0 0 #ffffff, 10017px 0 0 0 #ffffff;
    animation: dotTyping 1.5s infinite linear;
}
@keyframes dotTyping {

    0% {

        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 1), 9994px 0 0 0 rgba(255, 255, 255, 0.3), 10017px 0 0 0 rgba(255, 255, 255, 0.1);
    }

    16.77%{
        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 0.1), 9994px 0 0 0 rgba(255, 255, 255, 1), 10017px 0 0 0 rgba(255, 255, 255, 0.3);
    }

    33.333%{
        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 0.3), 9994px 0 0 0 rgba(255, 255, 255, 0.1), 10017px 0 0 0 rgba(255, 255, 255, 1);
    }
    50% {
        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 1), 9994px 0 0 0 rgba(255, 255, 255, 0.3), 10017px 0 0 0 rgba(255, 255, 255, 0.1);
    }

    66.667% {

        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 0.1), 9994px 0 0 0 rgba(255, 255, 255, 1), 10017px 0 0 0 rgba(255, 255, 255, 0.3);
    }
    83.333% {

        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 0.3), 9994px 0 0 0 rgba(255, 255, 255, 0.1), 10017px 0 0 0 rgba(255, 255, 255, 1);
    }

    100% {

        box-shadow: 9970px 0 0 0 rgba(255, 255, 255, 1), 9994px 0 0 0 rgba(255, 255, 255, 0.3), 10017px 0 0 0 rgba(255, 255, 255, 0.1);
    }
}


.connecting-call{
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    /* H3_22px,24px */
    font-family: Rubik-Regular;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 109.091% */
    position:absolute;
    top:70%;
}
.connecting-call-small{
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    /* H3_22px,24px */
    font-family: Rubik-Regular;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 109.091% */
    position:absolute;
    top:70%;
}
.online-session-placeholder-logo {
    width: 60%;
    height: auto;
}

.OTSubscriberContainer {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;
    /*background-color: #404040;*/
    /*border: solid 5px #ffffff!important;*/
    background-color: #ffffff !important;
}

.video-OTSubscriber {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px !important;
    /*background-color: #404040;*/
    border: solid 5px #ffffff !important;
    background-color: #ffffff !important;
}


.video-OTPublisher-container {
    position: absolute;
    top: 22px;
    right: 15px;
    /*border: solid 2px #ffffff;*/
    background-color: #ffffff;
    width: 105px;
    height: 85px;
    border-radius: 20px !important;
}

.video-OTPublisher {
    border: solid 2px #ffffff !important;
    /*background-color: #ffffff!important;*/
    border-radius: 50px !important;
}

.video-OTPublisher {
    width: 100%;
    height: 100%;
}

.video-OTPublisher-drag {
    height: 7px;
    width: 27px;
    right: 15px;
    position: absolute;
    top: 8px;
    cursor: move;
}

.video-OTPublisher-top {
    height: 22px;
    width: 100%;
    background: #4B4B4B;
    border-radius: 20px !important;

}

.video-OTPublisher > div {
    width: 100%;
    height: 100%;
}

.video-OTPublisher div {
    border-radius: 20px !important;

}

.OTPublisherContainer {
    width: 100% !important;
    height: 100% !important;
}

.OT_edge-bar-item {
    display: none !important;
}

.video-controls {
    height: fit-content;
    width: 50px;
    outline: none;
    border: none;
    position: absolute;
    left: 10px;
    top: 30%;
}

.video-control:nth-last-child(n+2) {
    margin-bottom: 15px;
}

.video-timer {
    top: 21px;
    left: 10px
}

.OT_widget-container {
    background: transparent !important;
    z-index: 0;
}

.video-OTStreams-screen .OT_widget-container {
    background: #404040 !important;
}

.online-video-controller {
    height: 85%;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: end;
    position: relative;
    left: 4%;
}

.doctor-mic-btn {
    height: 90%;
    width: 90%;
}

/*.doctor-mute-btn{*/
/*    height: 34px;*/
/*    width: 34px;*/
/*    margin-left: 13px;*/
/*    !*position: absolute;*!*/
/*    right: 10px;*/
/*    bottom: 10px;*/
/*}*/
/*.doctor-mute-vol-btn{*/
/*    height: 34px;*/
/*    width: 34px;*/
/*    margin-left: 13px;*/
/*    !*position: absolute;*!*/
/*    right: 40px;*/
/*    bottom: 10px;*/
/*}*/
.opentok-controls {

    display: flex;
    height: 2.5rem;
    width: 8.313rem;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    /*flex: 1 0 0;*/
    border-radius: 100px;
    border: 1.5px solid  #F0EFF2;
    background:  #FFF;



    /*z-index: 200002;*/
}
.opentok-controls-container{
    display: flex;
    justify-content: space-between;
    justify-content: space-around;
    position: absolute;
    bottom: -55px;
    right: 5px;
    width: 100%;
}
.tokbox-mobile{
    width:100%;
    height:100%;
}
.opentok-controls-container-mobile{
    bottom: 0!important;
    display: flex;
    width: 375px;
    height: 90px;
    /* flex-direction: column; */
    justify-content: space-evenly;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    position: absolute;
    border-radius: 12px 12px 0px 0px;
    background: rgba(52, 49, 49, 0.80);

}
.opentok-controls-holder {
    /*display: flex;*/
}

.opentok-control-holder {
    height: 3.125rem;
    width: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* display: flex; */
    background-color: rgba(255, 255, 255, 0.20);

    /*display: flex;*/
}

.opentok-control-holder:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.end-call-btn {
    height:2.5rem;
    width: 6.688rem;
    min-width: 6rem !important;
    /*z-index: 200002;*/
    display: flex;
    background-color: #FF6464;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

}
.end-call-btn-mobile{
    width:3.125rem !important;
    height: 3.125rem !important;
    min-width: 3.125rem !important;
}

.end-call-btn:hover {
    background-color: #e65e5a !important;
}

.end-call-btn-svg {
    /*height: 16px;*/
    width: 20px;
}
.end-call-btn-svg-mobile{
    width: 30px;
}

.end-call-btn-label {
    font-size: 14px;
    margin: 0 !important;
}

.opentok-control-holder:nth-last-child(n+2) {
    margin-bottom: 10px;
}

.tokbox-patient-name-container {
    font-family: Rubik-Medium;
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 23px;
    z-index: 1;
    left: 22px;
}
