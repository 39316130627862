.patient-personal-data-container {
    height: 120px;
    padding-right: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.patient-personal-data {
    background-color: #ffffff;
    height: 100%;
    /*width: calc(100% - 220px);*/
    /*margin-right: 30px;*/
    flex: 1;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}

.patient-personal-data-info {
    height: 100%;
}

.patient-personal-data-info .row {
    min-height: 35px;
    height: 33.333333%;
    display: flex;
    /*align-items: center;*/
}

.patient-personal-image-container {
    height: 100px;
    max-width: 100px;
    display: flex;
    align-items: center;
    margin: 0 30px;
}

.patient-personal-image {
    height: 100px;
    width: 100px;

    border-radius: 50%;
}

.patient-call-btn {
    width: 200px;
    border-radius: 10px !important;
    height: 100%;
    margin-left: 30px;
}

.sessions-data-container {
    overflow-y: auto;
    padding-right: 10px;
    height: calc(100% - 150px);
    /*min-height: max-content;*/
}

.session-data-container + .session-history-container {
    /*height: calc(100% - 965px)!important;*/
}

.session-history-container {
    /*height: 710px;*/
    min-height: max-content;
    /*height: 100%;*/
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 20px;
}
.session-history-container .ReactTable {
    min-height: 610px!important;
}
.session-history-header {
    padding: 5px 0 15px 0;
    color: #2717a4;
    font-size: 24px;

}

.session-history-exam-icon {
    height: 1.5rem;
    width: 1.5rem;
}

.session-history-exam-icon:nth-last-child(n+2) {
    margin-right: 15px;
}
