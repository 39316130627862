.modal-message-header {
    width: 100%;

    font-family: Rubik-Regular;
    font-size: 24px;
    font-weight: 300;
    padding: 20px 20px 10px 20px;
    text-align: left;
    color: #4c23b5;
}
.title-send-exam{
    /*padding-left: 35px;*/
   padding:20px 20px 10px 20px;
}
.image-container-send-exam{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    margin-top: 35px;
    width: 530px;
    height: 400px;

    background: #F2F0FE;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.send-exam-about{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */


    /* main/grey */

    color: #7F7F7F;
}
.send-exam-footer{
    justify-content: space-between!important;
}
.exit-session-btn-container {
    right: 20px;
    top: 20px;
    background-color: rgba(76, 35, 181, 0.1) !important;
}

.modal-message-body {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: 10px 20px;
}

.modal-message-footer {
    margin-top: 35px;
    display: flex;
    padding: 10px 20px 20px 20px;
    align-items: center;
    justify-content: flex-end;
}

.modal-message-footer-close {
    margin-right: 20px;
    font-size: 17px;
    text-align: center;
    color: rgba(76, 35, 181, 0.5);
}

.modal-message-footer .btn {
    font-size: 17px;
    height: 40px;
}

.exit-session-content {
    border-radius: 10px;
    box-shadow: 2px 2px 20px 0 rgba(76, 35, 181, 0.35);
    background-color: #ffffff;
}
.patient-send-exam{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    width: 635px;
    height: 816px;
    /* main/white */
    background: #FFFFFF;
    padding:35px;
    /* popup */
    box-shadow: 2px 2px 20px rgba(76, 35, 181, 0.35);
    border-radius: 10px;
}
.patient-send-fluentexam{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    width: 635px;
    height: 874px;
    /* main/white */
    background: #FFFFFF;
    padding:35px;
    /* popup */
    box-shadow: 2px 2px 20px rgba(76, 35, 181, 0.35);
    border-radius: 10px;
}
.patient-send-exam-body{
    /*padding-left: 35px;*/
    /*padding-top: 35px;*/
}
.service-code-media-body {
    overflow-y: inherit;

}
.Copy-this-link-to-share-session-with-another-clinician {
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: #7F7F7F;
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}
.Share-Session{
    font-family: 'Rubik-SemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    /* identical to box height, or 100% */


    /* update/main */

    color: #2E2E2E;

}
.close-modal-share{
    color: #2E2E2E;
    font-size: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 1.875rem;
}
.link-description {
    width: 26.75rem;
    height: 3.125rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /*margin: 1rem 0rem 1rem;*/
    border-radius: 0.25rem;
    border: solid 2px #EEE9F8;
    margin-left: 1.5rem;


}
.url-label{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.313rem;
    width:26.75rem;
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
    overflow: hidden;
    margin-left:1rem;

    /* update/main */

    color: #2E2E2E;
}
.share-records-modal{
    border-radius: 10px !important;
    border:none !important;
    width: 37.5rem;
    height: 11.875rem;

}
@media (min-width: 768px) and (max-width:1042px) {
    /*.share-records-modal{*/
    /*    width: 20rem !important;*/
    /*    height: 17.375rem !important;*/
    /*}*/
    .link-description{
        width: 26rem !important;
    }
}
.backdropClassName-share{
    display:flex !important;
    align-items: center !important;
    justify-content:center !important;
}
@media (min-width: 375px) and (max-width: 767px) {
    .share-records-modal{
        width: 20rem !important;
        height: 17.375rem !important;


    }
    .main-container{
        padding: 0.7rem !important;
    }
    .past-exams-sub-container{
        /*overflow: auto;*/
        height: 100%;
    }
    .Copy-this-link-to-share-session-with-another-clinician{
        width: 17rem !important;
    }
    .modal-message-footer{
        padding: 0px 20px 20px 20px !important;
        margin-top: 18px !important;
    }
    .title-send-exam{
        padding: 0px 20px 10px 20px !important;
    }
    .modal-message-body{
        padding: 0px 20px !important;
    }
}
.copy-button {

    flex-grow: 0;
    padding: 0 1.25rem;
    border-radius: 6.25rem;
    border: solid 1px #4c23b5;
    margin-right: 1.5rem;
    font-family: Rubik-Regular;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #FFFFFF;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6.25rem;
    height: 2.5rem;
    background: #7B5EC6;
    border-radius: 100px;
}
.modal-backdrop{
    width: auto !important;
}

