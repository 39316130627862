/*@media (min-width:375px)  { !* smartphones, iPhone, portrait 480x320 phones *! }*/
/*@media (min-width:769px)  { !* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. *! }*/
/*@media (min-width:1024px)  { !* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones *! }*/
/*@media (min-width:1281px) { !* hi-res laptops and desktops *! }*/
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.react-player video {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        background-color: white;transform: rotate(0deg);
    }
    to {
        background-color: rgba(76, 35, 181, 1.1)!important;
        transform: rotate(5deg);
    }

}

@keyframes flickerAnimation {
    0%   { background-color: rgba(76, 35, 181, 1); }
    50%  { background-color: rgba(76, 35, 181, 0.8); }
    100% { background-color: rgba(76, 35, 181, 1); }
    /*100% { background-color: rgba(76, 35, 181, 1)!important; }*/
}
@-o-keyframes flickerAnimation-o{
    0%   { background-color: rgba(76, 35, 181, 1)!important; }
    50%  { background-color: rgba(76, 35, 181, 0.8); }
    100% { background-color: rgba(76, 35, 181, 1); }
}
@-moz-keyframes flickerAnimation-moz{
    0%   { background-color: rgba(76, 35, 181, 1)!important; }
    50%  { background-color: rgba(76, 35, 181, 0.8); }
    100% { background-color: rgba(76, 35, 181, 1); }
}
@-webkit-keyframes flickerAnimation-webkit{
    0%   { background-color: rgba(76, 35, 181, 1)!important; }
    50%  { background-color: rgba(76, 35, 181, 0.8); }
    100% { background-color: rgba(76, 35, 181, 1); }
}

.mhd-btn {
    border-radius: 50px;
    /*background-color: #4c23b5;*/
    background-color: #4C23B5;
    color: #ffffff !important;
    height: 50px;
    min-width: 140px;
    font-size: 16px;
    font-family: Rubik-Regular;
    border: unset !important;
    outline: unset !important;
    cursor: pointer;
    outline: unset !important;
}
.mhd-reset-btn {
    margin-right: 20px !important;
    margin-left: 20px !important;
}
.mhd-btn-outline{
    border: 1px solid #3f2e6b !important;
    color: #3f2e6b !important;
    background-color: transparent !important;
    margin-right: 10px;
}
.mhd-btn-outline:hover{
    background-color: #3f2e6b;
    color: #ffffff !important;

}
.margin-modal{
    margin-right: 330px;
}
.reset-btn {
    border-radius: 50px;
    /*background-color: #4c23b5;*/
    background-color: #FFFFFF;
    color: #4C23B5 !important;
    height: 50px;
    min-width: 140px;
    font-size: 1vw;

    font-family: Rubik-Regular;
    border: 1px solid  #4C23B5;
    outline: unset !important;
    cursor: pointer;
    outline: unset !important;
}
.save-btn {
    border-radius: 50px;
    /*background-color: #4c23b5;*/
    background-color: #3f2e6b;
    color: #ffffff !important;
    height: 30px;
    min-width: 10px;
    font-size: 0.7vw;
    font-family: Rubik-Regular;
    border: unset !important;
    outline: unset !important;
    cursor: pointer;
    outline: unset !important;
}

.save-btn:hover {
    background-color: #36248e !important;
}
.mhd-btn:hover {
    background-color: #36248e !important;
}

.mhd-btn:disabled {
    color: #c4c4c4;
    background-color: #ececec;
    cursor: not-allowed;
}

.password-tooltip {
    font-family: Rubik-Regular;
    line-height: 1.6;
    letter-spacing: 0.6px;
}
.app-container.pushed-down{
    height: calc(100% - 80px) !important;
}
.app-container {
    overflow: hidden;
    height: 100%;
    width: 100vw;
    position: relative;
    /*padding: 30px 20px;*/
    background-color: #f7f7f9;
    /*display: flex;*/
    /*overflow-x: hidden;*/
}

/*.app-content {*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    padding: 3% 4% 0 4%;*/
/*}*/
.app-content {
    height: 100%;
    width: 100%;
    padding: 3% 1% 1% 2%;
}
.app-contentMedicalTeam {
    height: 100%;
    width: 100%;
    padding: 3% 12% 0 19%;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .loader {
        animation: spin infinite 2s linear;
    }
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 100001;
    width: 100px;
    height: 100px;
    margin: -75px 0 0 -75px;
    border: 10px solid #d3d1d1;
    border-radius: 50%;
    border-top: 10px solid #4c23b5;
}

.app-content-header {
    font-family: Rubik-Light;
    font-size: 1.8vw;
    color: #000000;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}
.new-tenant-admin{
    margin-bottom: 40px;
    font-family: Rubik-Medium;
    font-size: 1.6vw;
}
.tenantAdmin-header{
    font-family: Rubik-Medium;
}

.app-content-headerMedicalTeam {
    font-family: Rubik-Light;
    font-size: 35px;
    color: #000000;
    width: 100%;
    height: 40px;
    margin-bottom: 50px;
    margin-left: 500px;
}

.app-content-body {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: hidden;
}

    @media screen and (min-width: 600px) and (max-width: 1400px) {
    }
    /*.mhd-btn{*/
    /*    font-size: 1.2vw !important;*/
    /*}*/
    .physician-dashboard-meetings-count{
        font-size: 1vw !important;
    }

    .session-summary{
        font-size: 1.4vw!important;
    }
    .inbox-tab{
        font-size: 1vw !important;
    }
    .session-internalSummary{
        font-size: 1.4vw!important;
    }
    .skin-image-number{
        font-size: 15pt !important;
        margin-top: 10px !important;
    }
    .inbox-tab-content-value{
        font-size: 1vw !important;
    }
    .skin-image-title{
        font-size: 15pt !important;
    }
    .skin-image{
        max-width: 150px !important;
        height: 150px !important;
    }
    .OutLinedSelect__indicators{
        width: 42px !important;
    }
    .rbc-day-slot .rbc-event{
        flex-flow: column;
    }
    .css-8mmkcg{
        width: 15px !important;
        height: 15px !important;
    }
    .skin-exam-images-container{
        display: grid !important;
        grid-template-columns: auto auto!important;
    }
    .physician-setting-profile{
        margin-bottom: 30px;
    }
    .app-content{
        overflow: auto;
        -webkit-overflow-scrolling: touch;

    }
    .audio-player-control{
        width: 260px !important;
    }
    .exam-body{
        height: 75% !important;
    }
    .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event{
        min-height: 30px!important;
    }
    .rbc-header{
        font-size: 1.2vw !important;
    }
    .ReactTable .rt-thead .rt-tr{
        font-size: 0.75rem!important;
    }
    .rt-td{
        font-size: 1rem !important;
    }
    .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot{
        font-size: 1vw !important;
    }
    .rbc-event-content{
        font-size: 1vw!important;
    }
    .session-tab-icon{
        height: 25px !important;
    }
    .apexcharts-legend-text{

        font-size: 9pt !important;
    }
    /*.css-ssnzt1-control{*/
    /*    font-size: 1.3vw !important;*/
    /*}*/

    .summaryForPatients{
        font-size: 1.1vw !important;
    }
    /*.custom-input{*/
    /*    font-size: 1.6vw !important;*/
    /*}*/
    .css-yc6zq3-control{
        font-size: 1.3vw!important;
    }
    .outlined-select{
        font-size: 1vw !important;
    }
    .session-suggestions-container{
        font-size: 1vw !important;
    }
    .patient-personal-data-holder{
        font-size: 1vw !important;
    }
    /*.DateInput_input{*/
    /*    font-size: 1.6vw !important;*/
    /*}*/
    .condition-description-label{
        font-size: 1.2vw !important;
    }
    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
        min-height: 40px;
        justify-content : center;
        left: -100px !important;
    }
    .patients-table-container {
        overflow: scroll
    }
    .weekly-calendar-navigation-date{
        font-size: 1.3vw !important;
    }
    .inbox-tab{
        font-size: 1.3vw !important;
    }
    .app-content-body{
        font-size: 0.9vw;
    }
    .patients-table .ReactTable{
        height: unset !important;
    }
    .patients-table{
        height: unset !important;
    }
    .tokbox-chat-container{
        max-height:calc(1.8 * 429.5px);
        max-width:415.5px;
    }
    .ReactTable .rt-tbody{
        -webkit-overflow-scrolling: touch;
    }
    .ReactTable .rt-tbody .rt-tr-group{
        height: 100px;
    }

    .input-width{
        width: 34px !important;
    }
    .row>*{
        margin-top: unset;
        margin-bottom: -30px !important;
        padding-left: unset;
    }

    .web-app-logged-in-user{
        min-width: 300px !important;
    }
    .patient-personal-label{
        width: 80px !important;
    }
    .tokbox-chat-container{
        margin-left:unset !important;
    }
    .offline-session{
        width: 100% !important;
    }
    .exam-container{
        height: 750px !important;
        padding-bottom: 100px !important;
        width: 105% !important;
    }
    .mouthThroat-video{
        width:100% !important;
        height: 86% !important;

    }
    .throatMouth-title{
        left: 3%;
    }
    .snapShotImage{
        width:100% !important;
        height: 83% !important;
    }
    .image-trash-btn-video{
        bottom: 300px !important;
        left: 85% !important;
    }
    .image-trash-btn-video-online{
        bottom: 210px !important;
        left: 85% !important;
    }
    .throat-exam-results-container-throat{
        grid-gap: 6% 3% !important;
    }
    .nav-bar-container{
        width: 70px;
    }
    .menu-btn-img{
        width: 30px;
        height: 30px;
    }
    .patients-Name-after {
        font-size: 1vw !important;
    }
    .patients-Name {
        font-size: 1vw !important;
    }
    .patientAccounts-body{
        font-size: 1.1vw !important;
    }
    .add-patients-user{
        font-size: 1.1vw !important;
    }
    .menu-text-tabs{
        font-size: 1.2vw !important;
    }
    .menu-btn-text-Practice{
        font-size: 1.2vw !important;
    }
    .inbox-online-sessions, .inbox-offline-sessions{
        height: unset !important;
    }

    .userAccount-body{
        font-size: 1.1vw !important;

    }
    .exam-container{
        height: 1500px !important;

    }
    .ears-title{
        width : 35% !important;
    }
    /*.video-fullscreen-throat{*/
    /*    bottom: 30px !important;*/
    /*}*/
    .throat-grid-item{
        height: 32vh!important;
    }
    .throat-exam-results-container-throat{
        grid-template-rows: 22% 22% 22% 22% !important;
        grid-template-columns: 50% 50% !important;
    }
    .throat-exam-results-container-throat-online{
        grid-template-rows: 14% 14% 14% 14% 14% 14% !important;
        grid-template-columns: 60% !important;
    }
    .throat-exam-results-container-ears{
        grid-template-rows: 22% 22% 22% 22% !important;
        grid-template-columns: 50% 50% !important;
    }
    .throat-exam-results-container-ears-online{
        grid-template-rows: 14% 14% 14% 14% 14% 14% !important;
        grid-template-columns: 60% !important;
    }
    .throat-grid-item-capture-2{
        grid-row-start: 3 !important;
        grid-column-start: 1!important;
        grid-row-end: 4!important;
        grid-column-end: 2!important;
    }
    .throat-grid-item-capture-3{
        grid-row-start: 3!important;
        grid-column-start: 2!important;
        grid-row-end: 4!important;
        grid-column-end: 3!important;
    }
    .throat-grid-item-capture-4{
        grid-row-start: 4!important;
        grid-column-start: 1!important;
        grid-row-end: 5!important;
        grid-column-end: 2!important;
    }
    .throat-grid-item-capture-online-1{
        grid-row-start: 3 !important;
        grid-column-start: 1!important;
        grid-row-end: 4!important;
        grid-column-end: 2!important;
        cursor: pointer;
    }
    .throat-grid-item-capture-online-2{
        grid-row-start: 4 !important;
        grid-column-start: 1!important;
        grid-row-end: 5!important;
        grid-column-end: 2!important;
        cursor: pointer;
    }
    .throat-grid-item-capture-online-3{
        grid-row-start: 5!important;
        grid-column-start: 1!important;
        grid-row-end: 6!important;
        grid-column-end: 2!important;
        cursor: pointer;
    }
    .throat-grid-item-capture-online-4{
        grid-row-start: 6!important;
        grid-column-start: 1!important;
        grid-row-end: 7!important;
        grid-column-end: 2!important;
        cursor: pointer;
    }
    .physician-inbox-body{
        overflow: auto;
    }
    .inbox-tab-content-value{
        width: 25px;
    }
    .physician-form-footer{
        margin-top: 40px;
    }
    .session-tabs-container{
        font-size: 1.2vw!important;
    }
    .css-1csgkn2-singleValue{
        font-size: 1.3vw !important;
    }
    .search-input-patients-search-input::placeholder{
        font-size: 1.3vw !important;
    }
    .search-input-calendar-search-input::placeholder{
        font-size: 1.3vw !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
        height: 200px !important;
    }
    .selected-text{
        font-size: 1.1vw !important;
    }
    .custom-select-option{
        font-size: 1.1vw !important;
    }
    .langDropDown{
        font-size: 1.1vw !important;
    }
    .mhd-search-input{
        font-size: 1.5vw !important;

    }
    .patient-personal-data-info .row{

        line-height: 1;
    }
    .audio-player-label{
        font-size: 1.2vw !important;
    }
    /*.rbc-event-content{*/
    /*    display: none;*/
    /*}*/
    .rbc-event-label{
        font-size: 1vw !important;
        width: 10vw !important;
    }
    .physician-dashboard-meeting{
        padding: unset;
    }
    .physician-dashboard-cell{
        font-size: 1.1vw !important;
    }
    .meeting-time{
        font-size: 1.25vw !important;
    }


.red {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fd5a56 !important;
}

.mhd-color {
    color: #4c23b5 !important;
}

.space-between {
    justify-content: space-between !important;
}

.overflow {
    overflow-y: auto;
    height: 100%;
}
.PhoneInputCountry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    border-radius: 5px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}
.zoomer {
    /*top: 5px;*/
    /*left: 25px;*/
    height: 100%;
    display: flex;
    align-items: center;
    vertical-align: center;
    justify-content: space-around;
    z-index: 10000000001;
}
.zoomer > .zoom-tool {
    height: 18px;
    margin-right: 7px;
}
.zoomer{
    margin-right: 20px;
}
.zoomer-in {
    cursor: zoom-in;
}
.zoomer-out {
    cursor: zoom-out;
}
.main-app {
    height: 100%;
    width: 100%;
    overflow:hidden ;
}
.g-recaptcha {
    position: absolute;
    bottom: 5px;
    left: 10px;
}

@media print {
    body, .admin-tenant {
        display: block !important;
        position: relative !important;
        width: auto !important;
        height: auto !important;
        overflow: visible !important;
        margin-left: 0 !important;
    }

    .nav-bar-tenant-container{
        display:none;
    }
    .nav-bar-admin-container{
        display:none;
    }
    #reportPrintContainer{
        min-height: 788px !important;
        height: fit-content !important;


    }
}
@media (min-width: 576px) and (max-width: 1399px){
    .modal-dialog {
        max-width: 50.25rem !important;
    }
}
@media (min-width: 1400px) {
    .modal-dialog {
        max-width: 56.25rem !important;
        width: 56.25rem !important;
    }
    .media-dialog-snap{
        width: 56.25rem !important;
        max-width: 56.25rem !important;
    }
}
/*@media (min-width: 375px) and (max-width: 450px){*/
/*    .brightnessFilterNew {*/
/*        height: 2.625rem !important;*/
/*    }*/
/*    .brightnessFilterButtonSelected, .frameFilterWhiteNew{*/
/*        height: 2rem !important;*/
/*    }*/
/*}*/



@media (min-width: 375px) and (max-width: 767px) {
    .media-dialog-login{
        width: 100% !important;
        height: 97% !important;
    }
    .modal-dialog-body-login{
        width: 100% !important;
        height: 100% !important;
    }
    .auth-page{
        /*flex-direction: column !important;*/
    }
    .auth-page-content{
        flex-direction: column !important;
        align-items: center !important;
        justify-content: flex-start !important;
        width: 100% !important;
    }
    .auth-form-container{
        width: 85% !important;
    }
    .app-container {
        /*overflow-y: scroll !important;*/

    }
    .grid-container{
        height: auto !important;
        min-height: 65%;
    }

    .image-container-send-exam img {
        width: 13rem !important;
        margin-top: 15px !important;
    }
    .send-exam-footer {
        flex-direction: column !important;

    }
    .patient-send-exam {
        padding: 17px !important;
        height: fit-content !important;

    }
    .image-container-send-exam {
        width: 15rem !important;
        height: auto !important;
        margin-top: 0px !important;
    }
    .modal-message-footer .btn{
        margin-top: 1rem !important;
    }
    .patient-send-exam {
        max-width: 24.25rem !important;
        width: 24.25rem !important;

    }
    .frame-otoscope-exam{
        width: 19.375rem !important;
        height: 19.375rem !important;
    }
    .ears-exam-noVideo{
        width: 19.375rem !important;
        height: 19.375rem !important;
    }
    .patient-send-fluentexam {
        max-width: 24.25rem !important;
        width: 24.25rem !important;
        height: auto !important;
    }
    .media-dialog-snap-title{
        font-size: 1rem !important;
    }
    .media-dialog-snap-modal-close-btn{
        width: 5rem !important;
        left: 90% !important;
        height: 5rem !important;
        top: 12px !important;
    }
    .exit-session-dialog {
        max-width: 30.25rem !important;
        width: 30.25rem !important;
    }
    .media-dialog-video{
        padding: 1rem!important;

    }
    .modal-dialog{
        /*margin: 0!important;*/
        width: 95% !important;
        /*left: 2% !important;*/

    }
    .form-container {

        width:100% !important;

    }
    .title-container{
        flex-direction: column;
        align-items: start !important;

    }
    .lastUpdated{
        /*left: 3.3rem;*/
    }
    .tabClassName{
        word-break: break-word;
        width: 6.4rem;
        font-size:1rem!important;
        padding: 0.5rem 0.5rem !important;

    }
    .dtc-patient-profile{
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;

        max-height: 50vh !important;
    }
    .questionNote{
        width: 97% !important;
    }
    .vaccination-container{
        display:flex !important;
        flex-direction: column;
    }
    .vaccination-radio-label{
        margin-bottom: 1.063rem !important;
    }
    .vaccination-radio-container{
        flex-direction: column;
        padding-bottom: 1rem;
    }
    .condition-radio-container{
        width: 100% !important;
    }
    .vaccination-container-mobile > :not(:last-child) {
        border-bottom: 2px solid #F0EFF2;
    }
    .form-column{
        padding: 0 5px !important;
    }
    .modal-message-body{
        font-size: 16px !important;
    }
    .snapShotContainer{
        width: 3.25rem !important;
        height: 3.25rem !important;
    }
    .captures-length{
        left: 89% !important;
    }
    .frameFilterWhiteNew{
        width: 2.25rem !important;
        height: 2.25rem !important;
    }
    .radio-brightness-container{
        padding: 5px !important;
    }
    .frameFilterNew{
        padding:2px !important
    }
    .frameFilterWhite{
        width:2.25rem !important;
        height:2.25rem !important;
    }
    .notes-sendNotes-button{
        margin-bottom: 10rem !important;
    }
    .ears-exam-video-container{
        padding-left: 0!important;
    }
    .ear-exam-capture-container{
        margin-left: 0.1rem !important;
    }

}


@media (min-width: 1125px) {
    .tokbox-chat-container {
        position: relative;
        left: 12.5%;
    }
}
@media (min-width: 1125px) and (max-width: 1440px) and (max-height: 768px){
    .past-exams-sub-container{
        width: 82rem !important;
    }
}
    @media (min-width: 768px) and (max-width: 1125px) and (max-height: 768px){

    .patient-send-fluentexam {
        max-width: 35.25rem !important;
        width: 35.25rem !important;
        height: auto !important;
    }
    .patient-send-exam {
        max-width: 35.25rem !important;
        width: 35.25rem !important;
        height: auto !important;

    }
    .image-container-send-exam {
        width: 24rem !important;
        height: auto !important;
    }
    .image-container-send-exam img {
        width: 28rem !important;
    }
    .modal-message-footer{
        margin-top: 5px !important;
    }
    .title-send-exam{
        padding: 0px 20px 10px 20px !important;
    }
    .exit-session-dialog {
        max-width: 35.25rem !important;
        width: 35.25rem !important;
    }
    .modal-dialog {
        max-width: 42.75rem !important;
        width: 42.75rem !important;
    }
    .frameFilterNew, .brightnessFilterNew{
        width: 19rem !important;
    }
    .vaccination-container{
        display:flex !important;
        flex-direction: column;
    }
    .vaccination-radio-label{
        margin-bottom: 1.063rem !important;
    }
    .vaccination-radio-container{
        flex-direction: column;
        padding-bottom: 1rem;
    }
    .condition-radio-container{
        width: 100% !important;
    }
    .vaccination-container-mobile > :not(:last-child) {
        border-bottom: 2px solid #F0EFF2;
    }
    .form-column{
        padding: 0 5px !important;
    }
    .questionNote{
        width: 97% !important;
    }

}




/*@media (min-width: 501px) and (max-width: 700px){*/
/*    .ears-exam-fullScreen-button{*/
/*        left: 16rem !important;*/

/*    }*/
/*}*/
/*@media (min-width: 768px ){*/
/*    .send-exam-footer {*/
/*        flex-direction: column !important;*/

/*    }*/
/*}*/
ul{
    list-style-type: none;
}
.modal-dialog-centered{
    justify-content: center !important;
}
/*video::-webkit-media-controls-fullscreen-button {*/
/*    display: none !important;*/
/*}*/
/*video::-webkit-media-controls-start-playback-button {*/
/*    display: none !important;*/
/*}*/
