body {
    background: #ECEFF1;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    padding: 0;
}

#message {
    background: white;
    max-width: 360px;
    margin: 100px auto 16px;
    padding: 32px 24px 16px;
    border-radius: 3px;
}

#message h3 {
    color: #888;
    font-weight: normal;
    font-size: 16px;
    margin: 16px 0 12px;
}

#message h2 {
    color: #ffa100;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px;
}

#message h1 {
    font-size: 22px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 0 16px;
}

#message p {
    line-height: 140%;
    margin: 16px 0 24px;
    font-size: 14px;
}

#message a {
    display: block;
    text-align: center;
    background: #039be5;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 16px;
    border-radius: 4px;
}

#message, #message a {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#load {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 13px;
}

.expired-link-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 84px;
    /* align-self: center; */
    align-items: center;
}
.expired-link-title{
    font-family: 'Avenir-bold';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    margin-top: 40px;
    /* identical to box height, or 111% */

    text-align: center;

    /* main */

    color: #343131;
}
.expired-link-Message{
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 200;
    font-size: 22px;
    line-height: 24px;
    margin-top: 20px;
    /* identical to box height, or 109% */

    text-align: center;

    /* main */

    color: #343131;

}