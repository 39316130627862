.tech-info-container{
    z-index: 55;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 2rem 2rem 2rem;
    /*gap: 1.5rem;*/
    /*isolation: isolate;*/

    position: absolute;
    width: 37.5rem;
    height: 21.438rem;
    left: 36%;
    top: 18%;

    /* update/white */

    background: #FFFFFF;
    /* popup */

    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.5);
    border-radius: 0.625rem;
}
.tech-info-container-mobileTablet{
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.625rem 1rem;

    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #FFFFFF;
}
.tech-info-container::before{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 16px 11px;
    border-color: transparent transparent #FFFFFF transparent;
    position: relative;
    bottom:4.5%;
    left: 0%;
}
.tech-info-content{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap:2.75rem;
    row-gap:1.625rem ;
    margin-bottom: 1rem;
}
.tech-info-content-mobileTablet{
    display: grid;
    grid-template-columns: auto auto;
    column-gap:3rem;
    row-gap:1.625rem ;
    margin-bottom: 1.2rem;
}
.tech-info-title{
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height:1.313rem;
    text-transform: uppercase;
    margin-bottom:1.25rem ;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;

    /* update/main */

    color: #2E2E2E;
}
.tech-info-data{
    font-family: Rubik-Regular;

    max-width:8.75rem ;
    width:8.75rem ;


}
.tech-info-data-label{
    text-transform: uppercase;
    font-size: 0.875rem;
    color: #A7A7A7;


}
.tech-info-data-value{
    font-weight: 500;
    font-size: 1.125rem;
    color: #2E2E2E;
    /*word-break: break-all;*/
    text-overflow: ellipsis;
    width:9rem ;
    max-width:9rem ;
    max-height:2rem ;
    overflow: hidden


}
.tech-info-data{
    height:2.5rem ;
}
.tech-info-close-btn{
    height: 1rem;
    width: 1rem;
    position: relative;
    left: 80%;
    top: -0.5rem;
    cursor: pointer;
}
.tech-info-back-btn{
    width: 0.938rem;
    height:0.938rem ;
    /*height: 1rem;*/
    cursor: pointer;
    margin-right: 1rem;
}
.tech-info-path{
    border-bottom: 1px solid #F0EFF2;
    height: 1px;
    width: 110%;
    margin-bottom: 1rem;
    position: relative;
    left: -5%;
}
.tech-info-exam-icons{
    display: flex;
}