@font-face {
    font-family: Montserrat-Bold;
    src: url(./Montserrat-Bold.ttf);
}


.h-green {
    color: #228D97;
}

h-grey {
    color: #A5A5A7;
}

@font-face {
    font-family: Rubik-SemiBold;
    src: url(./Rubik-SemiBold.ttf);
}

@font-face {
    font-family: Rubik-Regular;
    src: url(./Rubik-Regular.ttf);
}

@font-face {
    font-family: Rubik-Medium;
    src: url(./Rubik-Medium.ttf);
}

@font-face {
    font-family: Rubik-Light;
    src: url(./Rubik-Light.ttf);
}

@font-face {
    font-family: Rubik-ExtraBold;
    src: url(./Rubik-ExtraBold.ttf);
}

@font-face {
    font-family: Rubik-Bold;
    src: url(./Rubik-Bold.ttf);
}

@font-face {
    font-family: Rubik-Black;
    src: url(./Rubik-Black.ttf);
}

@font-face {
    font-family: Montserrat-Bold;
    src: url(./Montserrat-Bold.ttf);
}

@font-face {
    font-family: SourceSansPro-Regular;
    src: url(./SourceSansPro-Regular.ttf);
}

@font-face {
    font-family: WorkSans-Regular;
    src: url(./WorkSans-Regular.ttf);
}
@font-face {
    font-family: Avenir;
    src: url(./Avenir.ttf);
}
@font-face {
    font-family: Avenir-bold;
    src: url(./AvenirLTStd-Roman.otf);
}
