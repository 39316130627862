.mhd-switch-input-container {
    display: flex;
    align-items: center;
}

.mhd-switch-input-container > div, .mhd-switch-input-container > label {
    margin-right: 15px;
}

.mhd-switch-cover {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;
    margin-bottom: 0 !important;
}

.mhd-switch-slider.round {
    border-radius: 34px;
}

.mhd-switch-slider.round:before {
    border-radius: 50%;
}

.mhd-switch-label {
    font-size: 14px;
    opacity: 0.5;
    color: #2e2e2e;
}

.mhd-switch-label.active {
    color: rgba(76, 35, 181, 0.5);
}

.mhd-switch-cover input {
    opacity: 0;
    width: 0;
    height: 0;
}

.mhd-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 1px;
    background-color: #ececec;
    -webkit-transition: .4s;
    transition: 0.4s;

}

.mhd-switch-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    border-image-slice: 1;
    background-color: #4c23b5;
    -webkit-transition: .4s;
    transition: 0.4s;
    margin-top: -4px;
    margin-left: -4px;
}

.mhd-switch-input:checked + .mhd-switch-slider {
    background-color: rgba(76, 35, 181, 0.5);
}

.mhd-switch-input:focus + .mhd-switch-slider {
    box-shadow: 0 0 1px #2196F3;
}

.mhd-switch-input:checked + .mhd-switch-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
