.builder-settings {
    margin: 0 10%;
    width: 80% !important;
}

.builder-setting-body {
    /*max-width: 1200px;*/
    min-height: 772px;
    /*height: max-content;*/
    overflow-y: auto;
    padding: 24px 34px 20px 24px;
    border-radius: 10px;
    background-color: #ffffff;
    position: relative;
}

.builder-setting-profile {
    font-size: 24px;
    color: #4c23b5 !important;
}

.switch-on {
    width: 34px;
    height: 14px;
    position: relative;
    left: -460px;
    bottom: -5px;
    opacity: 0.5;
    min-width: 30px;
    background-color: rgba(76, 35, 181, 0.5) !important;
}

.admin-profilePic {
    width: 47px;
    height: 47px;
    position: relative;
    top: 143px;
    right: -88px;
    z-index: 1;
    cursor: pointer;

}

.form-profile-img {
    width: 131px;
    height: 131px;
    border: solid 2px #ffffff;
    border-radius: 50%;
    position: relative;
    top: -45px;

}

.form-profile-img-default {
    width: 155px;
    height: 155px;
    border-radius: 50%;
}


.setting-disabled {
    float: right;
    width: 65px;
    height: 20px;
    display: flex;
    opacity: 0.5;
    font-family: Rubik;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2e2e2e;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    position: relative;
    right: 26%
}

.builder-header:nth-last-child(n) {
    float: right;
    margin-left: 20px;
    justify-content: flex-end;

}

.setting-right {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 1400px) {
    .form-container {
        display: unset !important;
        width: 100%;
        padding: 30px 95px 0 95px;
    }
    .form-column image-column {
        margin-bottom: 10px !important;
    }
    .form-column {
        flex: unset !important;
        margin: 0 !important;
        padding: 0 20px;
    }

    .form-column:nth-last-child(n+2) {
        margin-bottom: 40px !important;
    }

    .form-footer {
        position: relative !important;
    }
    .image-column {

        margin: 0 !important;
        padding: 0 20px;
    }
    .edit-profile-img-btn{
        width: 5% !important;
        height: 5% !important;
    }
}

.form-container {
    display: flex;
    width: 50%;
    padding: 54px 0px;

}

.form-rows {
    float: left;
    width: 100%;
    height: 300px;
}

.form-column {
    flex: 1;
}

.image-column {
    flex: 1 !important;
    max-width: 155px;
    position: relative;
}

.form-column:nth-last-child(n+2) {
    margin-right: 40px;
}

.form-input-container:nth-last-child(n+2) {
    margin-bottom: 40px;
}


.form-footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 50px);
    left: 0;
    height: 100px;
    margin: 0 25px;
    border-top: 1px solid rgba(145, 148, 206, 0.15);
}

.form-save-btn {
    width: 168px;
    height: 50px;
    right: 0 !important;
    position: absolute;
}

.edit-profile-img-btn {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    position: absolute;
    top: 105px;
    left: 105px;
    padding: 0 !important;
}

.form-profile-img-edit-btn {
    height: 55%;
    width: 55%;
}
