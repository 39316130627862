.auth-page {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}
.auth-page-iframe{
    position: absolute;

}

.auth-mhd-logo {
    height: 73px;
    width: 64px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.auth-page-content {
    height: 74%;
    width: 77%;
}
.auth-page-content-modal{
    height: 84% !important;
}

.auth-page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.auth-form-container {
    width: 55%;
}

.auth-form-label {
    font-family: Rubik-Medium;
    font-size: 24px;
    font-weight: 500;
    color: #4c23b5;
    width: 100%;
    text-align: center;
}

.auth-form-inputs {
    width: 80%;
}

.container-auth-form-input {
    margin-top: 35px !important;
}

.auth-form-utils {
    display: flex;
    justify-content: flex-end;
    /*justify-content: space-between;*/
    width: 80%;
    margin-top: 30px;
}

.auth-form-back-to-login {
    width: 100%;
    text-align: center;
}

.label-login-remember-me, .auth-form-forgot-password, .auth-form-back-to-login {
    color: #9c9c9c;
    font-size: 18px;
}

.auth-form-back-to-login:hover, .label-login-remember-me:hover, .auth-form-forgot-password:hover {
    color: #858383;
}

.auth-placeholder-container {
    width: 30%;
    min-width: 520px;
    padding: 30px 0;
}

.auth-placeholder-image {
    width: 100%;
}

.auth-placeholder-company {
    color: #4c23b5;
    font-family: Rubik-Medium;
    font-size: 33px;
    text-align: center;
}

.auth-placeholder-slogan {
    text-align: center;
    color: #9c9c9c;
    font-size: 18px;
}

.auth-btn {
    width: 78%;
    margin-top: 50px;
}
.auth-btnUpdated {
    width: 78%;
    margin-top: 0px;
}


/*recaptcha*/
.rc-anchor-invisible, .rc-anchor-invisible-text, .grecaptcha-badge, #recaptcha-token, .grecaptcha-logo iframe {
    width: 280px !important;
    height: 65px !important;
}

.grecaptcha-badge {
    bottom: 30px !important;
    transform: translate(20px);
}

.passwordVisibility{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 319px;
    right: 0;
    cursor:pointer;
    z-index: 10;
}
.passwordVisibilityAdmin{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 183px;
    right: 0;
    cursor:pointer;
    z-index: 10;
}
.passwordVisibility2{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 190px;
    right: 0;
    cursor:pointer;
    z-index: 10;
}
.ConfirmedPasswordVisibility{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 0;
    cursor:pointer;
    z-index: 10;
}
.setNewPassVisibility{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 190px;
    right: 0;
    cursor:pointer;
    z-index: 10;
}

/*.grecaptcha-badge { visibility: hidden; }*/
.modal-dialog-body-login{
height: 35rem !important;
}
.media-dialog-login{
    width: 50rem ;
    height:90% ;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    /*padding: 0 1.875rem 3.125rem;*/
    gap: 1.25rem;
    justify-content: center;
    /*position: absolute;*/
    /*left: 15%;*/
    /*top: 25%;*/
    border-radius: 0.75rem;
    /* white */
    /*background: #FFFFFF;*/
}
.media-dialog-login-content{

    border: 0px solid rgba(0,0,0,.2) !important;

}