.ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    border: unset;
    border-bottom: 1px solid #f7f7f9;
    /*height: calc(100% - 60px);*/
    /*min-height: 623px;*/
    min-height: 100%;
    max-height: 100% !important;
    height: 100% !important;
}

.ReactTable .rt-tr {
    flex: 1 0 auto;
    display: inline-flex;
}

.rt-expandable {
    visibility: hidden !important;
}
.ReactTable .rt-thead .rt-tr {
    font-family: OpenSan, sans-serif;
    font-size: 19px;
    font-weight: 300;
    color: #888888;
    line-height: 1.16;
    /*border-bottom: 2px solid #C8E7EA;*/
    /*border-top: 2px solid #C8E7EA;*/
    height: 60px;
}

.rt-th.-cursor-pointer {
    align-items: center;
    display: flex;
}

.-sort-asc, .-sort-desc {
    box-shadow: none !important;
    border: none;
    background-color: rgba(245, 245, 245, 0.8);
}

.table-loader {
    /*display: block;*/
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    width: 100%;
    background-color: #F9F8FC;
}

.cursor-pointer:focus {
    width: 0;
}

.ReactTable .rt-th, .ReactTable .rt-td {
    hite-space: nowrap;
    text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-th {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: transparent;
}

.ReactTable .rt-thead .rt-td {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: transparent;
}

.rt-thead {
    padding-right: 30px;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
    cursor: pointer;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    /*overflow: hidden;*/
    text-overflow: ellipsis;
}

.rt-resizable-header-content {
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
}

.ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
}

.ReactTable .rt-tbody {
    width: 100%;
    justify-content: flex-start;
    transform: translatez(0)!important;
    /*margin: 10px 0;*/

}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: unset;
    height: max-content;
    padding-right: 30px;
    flex: unset !important;
    /*margin-right: 20px;*/
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr {
    max-height: 100px;
}

.ReactTable .rt-tbody .rt-tr-group:hover .rt-tr {
    background-color: rgba(0, 0, 0, 0.03);
}

.ReactTable .rt-tr {
    padding-left: 10px;
    padding-right: 10px;
}

.ReactTable .rt-tbody .rt-td {
    border-right: none;
    background-color: transparent;
}

.rt-noData {
    top: 250%
}

.-cursor-pointer:focus {
    outline: none;
}

/**************************************************/

.Table__pagination {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
}

.Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
}

/***********************************************/
.nova-select-input {
    -webkit-appearance: none;
    background: url('../../../assets/arrow-down.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}

.-sort-desc:after {
    content: " ";
    white-space: pre;
    float: left;
    background: url('../../../assets/arrow-down.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
    margin-bottom: 1.5px;
}

.-sort-asc:before {
    content: " ";
    white-space: pre;
    float: left;
    margin-bottom: 2.5px;
    background: url('../../../assets/arrow-up.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}

.-cursor-pointer > * {
    float: left;
}

.rt-tr.-odd, .rt-tr.-even {
    height: 100px !important;
    border-bottom: 1px solid rgba(145, 148, 206, 0.15);

}

.rt-td {
    background-color: #ffffff;
    margin: auto;
    text-align: left;
    color: #000000;
    font-size: 16px;
    line-height: 1.16;
    letter-spacing: normal;
}

.locked .rt-td {
    color: #b4b4b4 !important;
}

.Table__pageButton {
    /*height: 30px;*/
    /*width: 30px;*/
    font-family: OpenSan, sans-serif;
    /*border: 1px solid #e4e4e4;*/
    align-items: center;

    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #2e2e2e;
}

.Table__pageButton {
    margin-right: 10px;
}

.Table__pageButton:focus {
    outline: unset !important;
}

.Table__pageButton:hover {
    font-weight: bold;
}

.Table__pageButton--active {
    width: 24px;
    height: 24px;
    /* flex-grow: 0; */
    /* display: flex; */
    /* flex-direction: row; */
    justify-content: center;
    /* align-items: center; */
    /* grid-gap: 10px; */
    /* gap: 10px; */
    padding: 0;
    border-radius: 3px;
    border: solid 1px #eee9f8;
    background-color: #fff;
    font-weight: bold;
}
.Table__prevPageWrapper{
    margin-left: 10px;
    width: 65px;
}
.Table__pageButton_arrow {
    margin: 0;
    position: relative;
    /* top: 50%; */
    display: contents;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.Table__pageButton--active:hover {
    border: unset !important;
    cursor: not-allowed;
}

.Table__pageButton_arrow:hover {
    border: unset !important;
}

.table-filter {
    display: none;
}

.filter-toggle {
    position: relative;
    left: 0;
    margin-right: 3px;
    text-decoration: none;
}

.ReactTable .rt-tbody {
    /*overflow: hidden!important;*/
}

.checkbox-th {
    padding-right: 21px !important;
    text-align: center;
}

.table-name {
    font-family: Rubik-Medium;
}

.table-name-cell {
    width: max-content;
}

.table-last {
    width: max-content;
    /*display: flex;*/
    justify-content: space-between;
}

.table-last-date {
    text-align: left;
    min-width: 115px;
}

.table-last-time {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    min-width: 80px;
}

.table-last-physician {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    min-width: 80px;
}

.redrow {
    background-color: #fff7f7 !important;
}
.unredrow{
    background-color: rgba(0, 0, 0, 0.05) !important;

}

.row-greyed {
    background-color: rgba(0, 0, 0, 0.05) !important;
}
.rt-tr.text-greyed .rt-td {
    color: grey !important;
}
.text-greyed {
    color: black !important;
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.rt-th {
    color: #7B5EC6;
    display: flex;
    align-items: center;
}

