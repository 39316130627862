.error-svg, .warning-svg {
    position: relative !important;
    margin-top: 0.5px;
}

.error-message-label, .warning-message-label {
    display: flex;
    align-items: center;
    justify-content: start;
    /*float: left;*/
    width: 100%;
    min-height: 30px;
}

.error-label, .warning-label {
    font-size: 16px !important;
    font-weight: 300 !important;
    max-width: calc(100% - 20px);
    font-family: Rubik-Regular;
    font-stretch: normal !important;
    font-style: normal !important;
    margin-left: 5px !important;
    /*line-height: 1.71!important;*/
    letter-spacing: 0.35px !important;
    position: relative !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
}
