.notes-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notes-body:last-child{
    margin-bottom: 5rem;
}
.notes-container{
    font-family: 'Rubik-Regular', serif;
    color: #2E2E2E;
    width: 100%;
}
.notes-title{
    font-size: 1.125rem;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;

}
.notes-template-card-container{
    padding: 0;
    width: 85%;
    height: max-content;
    background: #FFFFFF;
    border: 1px solid #F0EFF2;
    border-radius: 8px;
}
.notes-title-section{
    display: flex;
    padding-top: 2rem;
    /*padding-left: 1rem;*/
    /*padding-right: 1rem;*/
    margin-left: 7%;
    width: 85%;

    margin-bottom: 2.5rem;
    justify-content: space-between;
    align-items: center;
}
.notes-template-card-container-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    background: #F5F5F7;
    border-top-right-radius:  8px;
    border-top-left-radius:  8px;
    margin-bottom: 0.5rem;

}
.notes-template-card-title{
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    padding-left: 1rem;
    font-family: 'Rubik-Medium', serif;
}
.notes-copyNotes-button{
    font-family: Rubik-Medium, serif;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    width: 8.5rem;
    height: 2rem;
    background: #FFFFFF;
    border: 0.0938rem solid #F0EFF2;
    border-radius: 100px;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #7B5EC6;
}
.notes-template-card-container-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70%;
    justify-content: center;
    padding: 1rem;
}
.notes-template-card-body-main{
    /*width: 14rem;*/
    border: none;
    font-family: 'Rubik-Regular', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.5rem;
    background-color: #F8F8F8;
    border-radius: 0.25rem;
    color: #2E2E2E;
    min-height: 4.5rem;
    height: fit-content;
    max-height: 8rem;
    resize: none;
    width: 100%;
}
.notes-template-card-body-main::placeholder{
    color:#CBCBCB;
    font-style: italic;
    font-family: 'Rubik-Regular';
    font-size:0.875rem ;


}
.note-examination-card-title{
    font-family: 'Rubik-SemiBold', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    width: 45%;
}
.notes-examination-card-firstLine{
display: flex;
  justify-content: space-between;
    margin-bottom: 0.75rem;

}
.notes-examination-card-container{
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    padding-top: 0.5rem;
    margin-bottom: 1.25rem;
}
.notes-examination-path{
    width: 100%;
    border-bottom: 0.0625rem solid #F0EFF2;
}
.note-examination-card-value{
    font-family: 'Rubik-Regular', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2E2E2E;

}
.notes-bloodPressure-main-text{
    font-family: 'Rubik-Regular', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #2E2E2E;
}
.note-examination-card-input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    width: 100%;
    min-height: 2.5rem;
    height: auto;
    max-height: 6.5rem;
    background: #F8F8F8;
    border-radius: 0.25rem;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    border: transparent;
    resize: none;
    box-sizing: border-box;
}
.note-examination-card-input::placeholder{
    color: #CBCBCB;
}
.note-examination-card-input::-ms-input-placeholder{
    color: #CBCBCB;
}
.note-examination-card-input:focus{
    outline: none;
}
.note-input-Body_Temperature:focus{
    border: 1px solid #FFC031;
}
.note-input-BloodPressure:focus{
    border: 1px solid #E53724;
}
.note-input-Saturation:focus{
    border: 1px solid #80E1E3;
}
.note-input-Covid19:focus{
    border: 1px solid #7B5EC6;
}
.note-input-Strep:focus{
    border: 1px solid #7B5EC6;
}
.note-input-Throat:focus{
    border: 1px solid #E76B82;
}
.note-input-Heart:focus{
    border: 1px solid #FD5A56;
}
.note-input-Lungs:focus{
    border: 1px solid #79ADCD;
}
.note-input-Abdomen:focus{
    border: 1px solid #8B487C;
}
.note-input-Ears:focus{
    border: 1px solid #8B487C;
}
.note-input-Skin:focus{
    border: 1px solid #FF7B4A;
}
.notes-body-section{
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
.notes-sendNotes-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15.5rem;
    height: 2.5rem;
    background: #7B5EC6;
    border-radius: 100px;
    flex: none;
    align-self: center;
    flex-grow: 0;
    font-family: 'Rubik-Medium', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 6rem;
    border: 1px solid transparent;
}
.notes-arrow{
    color: #2E2E2E;
    margin-right: 1rem;
}
/*.notes-template-card-body-main {*/
/*    resize: none;*/
/*    width: 100%;*/
/*}*/

strong {
    font-weight: bold;
}
.notes-copied{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 16px 12px;
    gap: 0.5rem;
    /*position: absolute;*/
    width: 16.5rem;
    height:3.5rem;
    right: calc(50% - 264px/2 + 0.5px);
    top: 5.625rem;
    background: rgba(46, 46, 46, 0.9);
    border-radius: 0.25rem;
}
.notes-sent{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 16px 12px;
    gap: 0.5rem;
    /*position: absolute;*/
    width: 16.5rem;
    height:3.5rem;
    /*right: calc(50% - 264px/2 + 0.5px);*/
    /*top: 5.625rem;*/
    background: rgba(46, 46, 46, 0.9);
    border-radius: 0.25rem;
}
.notes-copied-text{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height:1rem;
    color: #FFFFFF;
}