.video-media-header {
    border-bottom: unset !important;
}

.video-player-cont {
    min-height: 340px;
    width: 300px;
}

.video-player-cont:nth-last-child(n+2) {
    margin-right: 50px;
}
.mouthThroat-video{
    width: 86%;
    height: 81%;
    text-align: center;
}

.frame-throatMouth{

    height:100% !important;
    width: 100% !important;
    background: #000000;
    border-radius: 16px;

}
.video-player-frame {
    height: 300px;
    width: 300px;
    background-color: #000000;
    /*margin-bottom: 20px;*/
    /*border-radius: 20px;*/
}

.video-player-label {
    font-family: Rubik-Medium;
    padding: 0 0 15px 0;
    font-size: 25px;
}
.video-player-control {
    width: 300px;
}

.video-player-controllers {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 5px 10px;
}

.video-player-play-controllers {
    display: flex;
    justify-content: space-between;
    width: max-content;
    align-items: center;
}

.video-player-timing {
    font-size: 18px;
    margin-bottom: 0;
}

.video-player-playing-control {
    height: 20px;
    width: 19px;
    margin-right: 10px;
}

.video-player-timing {

}

.video-player-custom-controllers {
    display: flex;
    justify-content: space-between;
    width: max-content;
    align-items: center;
}

.video-player-volume-control {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.video-player-screen-control {
    height: 20px;
    width: 20px;
}


.video-media-footer {
    padding: 0px 10px 25px 0;
    width: 75%;
    min-width: 550px;
}

.video-media-save {
    float: right;
    width: 120px;
    height: 40px !important;
}

.video-player-range {
    margin: 18px 0;
    width: 100%;
    -webkit-appearance: none;

}

input[type=range].video-player-range {
    width: 100%;
    margin: 4.6px 0;
    -webkit-appearance: none;
}

input[type=range].video-player-range:focus {
    outline: none;
}

input[type=range].video-player-range::-webkit-slider-runnable-track {
    /*background: #8ebabe;*/
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 3px;
    cursor: pointer;
}

input[type=range].video-player-range::-webkit-slider-thumb {
    margin-top: -6px;
    height: 12px;
    width: 12px;
    /*border: 0;*/
    /*border-radius: 50px;*/
    cursor: pointer;
    /*-webkit-appearance: none;*/
}

.video-player-range:focus::-webkit-slider-thumb {
    box-shadow: unset !important;
}

.video-player-range::-ms-fill-lower {
    border-radius: 1rem;
}

.video-player-range::-ms-fill-upper {
    margin-right: 15px;
    border-radius: 1rem;
}

.video-fullscreen-throat{
    position: relative;
    bottom: 5.4rem;
    left: 65%;
    color: #FFFFFF;
    cursor: pointer;
}
.video-fullscreen-ears{
    /*margin-left: 10px;*/
    position: relative;
    bottom: 68px;
    left: 44%;
}

.video-fullscreen {
    margin-left: 10px;
}
.vp-lbl-ears-video {
    color: #4c23b5;
}

.vp-frame-ears-video {
    border: solid 1px rgba(76, 35, 181, 0.5);
}

.vpc-cont-ears-video {
    border: solid 1px rgba(76, 35, 181, 0.5);
}

input[type=range].vp-range-ears-video::-webkit-slider-thumb {
    background: #4c23b5;
}

.vp-range-ears-video:hover::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #4c23b5 !important;
}

.vp-range-ears-video::-ms-fill-lower {
    background-color: #4c23b5 !important;
}

.vp-range-ears-video::-ms-fill-upper {
    margin-right: 15px;
    background-color: #4c23b5 !important;
    border-radius: 1rem;
}

.vpc-cont-ears-video {
    background-color: #eee9f8;
}

.vp-ears-video path {
    fill: #4c23b5;
}

.vp-lbl-throat-video {
    color: #ff64ae;
}

.vp-frame-throat-video {
    border: solid 1px rgba(255, 100, 174, 0.5);
}

.vpc-cont-throat-video {
    border: solid 1px rgba(255, 100, 174, 0.5);
}

input[type=range].vp-range-throat-video::-webkit-slider-thumb {
    background: #ff64ae!important;
}

.vp-range-throat-video:hover::-webkit-slider-thumb:hover {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem #ff64ae !important;
}

.vp-range-throat-video::-ms-fill-lower {
    background-color: #ff64ae !important;
}

.vp-range-throat-video::-ms-fill-upper {
    margin-right: 15px;
    background-color: #ff64ae !important;
    border-radius: 1rem;
}

.vpc-cont-throat-video {
    background-color: #eee9f8;
}

.vp-throat-video path {
    fill: #ff64ae;
}

.audio-player-cont {
    width: max-content;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.audio-player-control {
    border: 1px solid;
    width: 315px;
    padding: 5px 10px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-left: -15px;
}

.audio-player-controllers {
    width: 315px;
    display: flex;
    align-items: center;
}

.audio-player-playing-control {
    height: 22px;
    width: 22px;
    margin-right: 10px;
}

.audio-player-screen-control {
    height: 24px;
    width: 30px;
}

.audio-player-label {
    margin-bottom: 0 !important;
    margin-right: 10px;
}
.audio-player-range{
    width: 100% !important;
}
input[type=range].audio-player-range::-webkit-slider-runnable-track {
    /*background: #8ebabe;*/
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 3px;
    cursor: pointer;
}

input[type=range].audio-player-range::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    margin-top: -5px;
}

.audio-player-label {
    font-family: Rubik-Light;
    font-size: 0.9vw;
}

.audio-dotName-label {
    font-family: Rubik-Medium;
}

.audio-popup {
    right: 200px !important;
    top: 45px !important;
    min-width: 300px!important;
}
.audio-popup .popover-body{
    padding-left: 0!important;
}
.audio-popup .arrow {
    display: none;
}

.audio-popup-filter {
    padding: 5px 20px;
    font-size: 16px!important;
    display: flex;
    justify-content: space-between;
    min-width: 300px;
}

.audio-popup-filter .audio-popup-filter-name, .audio-popup-filter a, .audio-popup-filter a:visited, .audio-popup-filter a:link, .audio-popup-filter a:active, .audio-popup-filter a:visited  {
    cursor: pointer;
    color: black;
    text-decoration: transparent!important;
}
.audio-popup-filter .audio-popup-filter-name:hover, .audio-popup-filter a:hover {
    color: #5e5e5e;
    text-underline: transparent!important;
}

.audio-popup-filter-active .audio-popup-filter-name{
    font-family: Rubik-Bold;
}

