.past-exams-container{
    display: flex;
    width: 100%;
    justify-content: center;
    /*margin-top: 8rem;*/
}
.past-exams-table-container{
    /*width: 90%;*/
    max-height: 78vh;
    min-height: 20vh;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

}
.past-exams-table{
    width: 98%;
    height: auto!important;
    min-height: 20rem;
    max-height: 82rem !important;
    /*height: 82vh !important;*/


}
.past-exams-sub-container{
    width: 100%;
    text-align: left;



}
.title-container-past-exam{
    display: flex;
    height: 2.25rem;
    align-items: center;
    justify-content: start;
    margin-bottom: 2rem;

}
.back-circle-icon{
    width: 2.25rem;
    cursor: pointer;
}
.past-exam-label{
   font-family: 'Rubik-SemiBold';
   font-style: normal;
   /*font-weight: 600;*/
   font-size: 1.625rem;
   line-height: 1.5rem;
   color: #2E2E2E;
    margin-left: 1rem;


   }
.back-to-exams-label{
   font-family: Rubik-Regular;
   font-style: normal;
   /*font-weight: 600;*/
   font-size: 1rem;
   line-height: 1.5rem;
   color: #FFF;

   }
.past-exam-session-card{
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: flex-start;
    padding:1rem;
    margin-bottom: 2rem;
    width: 100%;
    height: 9rem;
    /* update/white */
    background: #FFFFFF;
    border-radius: 0.625rem;
    cursor:pointer;
}
.past-exam-icon{
width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;

}
.past-exam-session-card-date{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: right;

    /* update/grey */

    color: #A7A7A7;
}
.past-exam-icons-container{
  margin-top: 0.7rem;
    height: 1.5rem;
    margin-bottom: 1.6rem;

}
.past-exams-summary{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: #2E2E2E;

}
.past-exams-summary-missing{
    color: #CBCBCB !important;
    font-style: italic !important;

}