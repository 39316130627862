.mobile-apps-download-page {
    position: relative;
    height: 100%;
}
.cls-1{isolation:isolate;}
.cls-2{fill:#fff;}
.cls-3{fill:#818285;}
.cls-3,.cls-4,.cls-5{mix-blend-mode:multiply;}
.cls-4{fill:#a5cd39;}
.cls-5{fill:#57c4cf;}
.cls-6{fill:#a5cd39;}
.cls-7{fill:#333;}
.mobile-apps-download-mhd-logo-container {
    /*top: 20px;*/
    /*left: 20px;*/
    top: 35% !important;
    max-height: 400px;
    max-width: 400px;


    display: block;
}

.mobile-apps-download-mhd-logo {
    width: 100%;
    height: 100%;
}

.mobile-apps-download-title {
    position: relative;
    height: 80px;
    top: 20px;
    padding-top: 5px;

    text-align: center;
    font-family: Rubik-Bold;
    font-size: 45px;
    color: #4C23B5;
}

.android-download-container {
    position: absolute;
    bottom: 50px;
    margin-top: 20px !important;
}

.app-download-title {
    text-align: center;
    font-size: 30px;
    width: max-content;
}

.app-download-container {
    height: 47px;
    max-width: 300px;
    z-index: 0;
    display: flex;
    justify-content: center;
    /*background-color: #2e2e2e;*/
}

.app-download-container img {
    height: 100%;
    max-width: 207px;
    z-index: -1;
    cursor: pointer;
}
