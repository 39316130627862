.new-modal-container{
    width: 20rem !important;
    height: 17.25rem !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1.875rem 3.125rem;
    gap: 1.25rem;
    justify-content: center;
    position: absolute;
    left: 15%;
    top: 25%;
    border-radius: 0.75rem;
    /* white */

    background: #FFFFFF;
}
/*@media (min-width:768px){*/
/*    .new-modal-container{*/
/*        width: 28rem !important;*/
/*        height: 24rem !important;*/
/*        left: -5%;*/
/*        padding-top: 4rem;*/
/*    }*/

/*}*/
.prescription-modal{
    width: 20rem !important;
    height: 17.25rem !important;
}
.modal-close-btn-prescription{
    width :1.25rem;
    height:1.25rem;
}
.modal-dialog-title{
    font-family: 'Rubik-Bold';
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
    color: #343131;
    margin-top: 3.125rem;
    margin-bottom: 1.25rem;

}
.modal-dialog-body{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    /* or 109% */


    /* main */

    color: #343131;
}
.modal-close-button{
    position: absolute;
    top: 1.5rem !important;
    right: 1.5rem !important;
}