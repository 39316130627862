html, body, #root {
    height: 100%;
    width: 100%;
    min-width: 100%;
    font-family: Rubik-Regular;
    background-color: #ffffff;
    overflow: hidden;
    overscroll-behavior: none;

}


/*html {*/
/*    zoom: 0.9;*/
/*    -moz-transform: scale(0.9);*/
/*    -moz-transform-origin: 0 0;*/
/*}*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
    cursor: pointer !important;
}

.normal {
    cursor: default !important;
}

.crosshair {
    cursor: crosshair !important;
}

.blocked {
    cursor: not-allowed !important;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    position: relative !important;
    transform: translate(-0%, -50%);
}

.h-centered {
    margin: 0px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -0%);
}

.v-centered {
    margin: 0;
    position: relative;
    top: 50%;
    transform: translate(-0%, -50%);
}

.bold {
    font-family: Rubik-Regular, sans-serif !important;
}

.abs {
    position: absolute !important;
}

.footer {
    /*float: left;*/
    display: flex;
    vertical-align: center;
    align-items: center;
    bottom: 2px;
    position: fixed;
    left: 1rem;
    color: #888888;
}

.inline {
    display: inline-block;
}

.inlineF {
    display: inline-flex;
}

.centered-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fliped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.media-dialog-ear-video video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-fullscreen-button {
    opacity: 0.0 !important;

}
