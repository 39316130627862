.exam-heart-container{
    display: flex;
    margin-top: 1rem;

}
.exam-skin-container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 1rem;

}
.exam-skin-container-tablet{
    grid-template-columns: auto;
}
.exam-ears-container-medium{
    display: flex;
    margin-top: 1rem;
    flex-direction: column;

}
.exam-heart-container-tablet{
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    display: grid;
    grid-template-columns: auto auto;

    gap: 1rem;

}

.exam-body-parts-card-mobile{
    flex-direction: row !important;
    height: 100% !important;
    padding: 0 !important;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.exam-heart-container-mobile{
    gap: 1rem;
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 2.5rem;
}

.exam-body-parts-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 16px;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
    width: 24%;
    /*height: 20.5rem;*/

    /* update/border */

    border: 1px solid #F0EFF2;
    border-radius: 0.625rem;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}
.exam-body-parts-image-div{
    width:100%;
    /*height: 9rem;*/
    /*height: auto;*/
    border-radius: 10px;
    display: flex;
}
.exam-body-parts-image{
    width:100%;
    height:100%;
    /*position: relative;*/
    /*bottom: 10px;*/
    object-fit: cover;
    /*object-position: 0px 0%;*/
    border-radius: 10px;

}
.stethoscope-image{
    object-position: 0px 0% !important;
}
.exam-body-parts-image-heart{
    object-position: 0px 0%;
}
/*.exam-body-parts-image-mobile{*/
/*    object-position: -41px 1px !important;*/
/*}*/
.audio-exam{
    display: flex;
    /*flex-direction: row;*/
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.5rem;
    gap: 8px;
    width: 95%;
    min-height: 2rem;
    background: #F8F8F8;
    border-radius: 6.25rem;
    margin-bottom: 0.5rem;
    color:#2E2E2E;
}
.exam-body-filter{
    width: 100%;
    margin-top: 1rem;
}
.audio-exam-play{
    width: 0.75rem;
    /*margin-right: 0.5rem;*/
    /*margin-left: 1rem;*/
}
.audio-exam-filter-label{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
    flex: none;
    order: 1;
    margin-left: 0.5rem;
    /*color:#2E2E2E;*/
    flex-grow: 0;
}
.audio-exam-bell{
    width: 0.85rem;
    margin-right: 0.5rem;

}
.audio-exam-left{
    display: flex;
    align-items: center;
    cursor: pointer;
    width:80%;
    margin-left: 0.35rem;
}
.exam-audio-wave{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 16px;
    z-index: 1;
    position: absolute;
    width: 47%;
    height: 5.8125rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2%;

    /* exams/heart */

    background: #FD5A56;
    /* shadow/player */

    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.waveform-container{
    width: 97.5%;
    display: flex;
}

.waveform-container-mobile{
    width: 95.5% !important;
}
.controls{
    height: 2.5rem;
    position: relative;
    bottom: 26px;
    background: #FD5A56;
    z-index: 5;
    align-items: center;
    padding-top: 0.5rem;
}
.controls-time{
    display: flex;
    margin-top: 0.4rem ;
}
.controls-time-container{
    display: flex;
    margin-top: 0.4rem ;
}
.controls-time-mobile-container{

}
.controls-time-mobile{
    margin-top: 0.5rem ;
    justify-content: space-between;
    font-size: 0.75rem;
}
.waveform-image{
   width: 4.5rem;
    margin-right: 0.75rem;
}
.waveform-image-mobile{
    width: 6rem !important;
    margin-left: -0.5rem;
}
.waveform-wrapper{
    width: 100%;
    position: relative;
    top: 15px;
}
.waveform-wrapper-mobile{
    top: 5px !important;
}

.player-progress {
    display: flex;
    postion: relative;
    height: 6px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 25px;
    margin: 0 5px;
    flex: 10;
    flex-basis: 100%;
    overflow: hidden;
}
.player-progress-filled {
    height: 6px;
    background: #FFFFFF;
    flex: 0;
    flex-basis: 0%;
    border-radius: 25px;
}
.timecode{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 0.5rem;
}
.controls-play-buttons{
    display: flex;
    justify-content: space-between;
    margin-right: 0.25rem;
}
.controls-play-buttons-mobile{
    margin-top: 0.55rem !important;
    justify-content: center !important;
}
.noAudioText{
    font-family: 'Rubik-Regular';
    font-style: italic;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-align: center;
    color: #A7A7A7;

}
.waveform-close{
    position: absolute;
    left: 97%;
   z-index: 5;
    bottom: 12%;
    width: 3rem;
}
.waveform-close-mobile{
    left: 94% !important;
}
.exam-body-parts-image-div-mobile{
    height: 100% !important;
}
.exam-filter-Container{
    width: 100%;
}
.exam-filter-Container-mobile{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.audio-exam-mobile{
    height: 70% !important;
}
.timecode-mobile{
    font-size: 0.75rem !important;
    margin-left:0.1rem;
}
.audio-exam-play-waveform-mobile{
    /*margin-left: 0.5rem !important;*/
    /*height: 15px !important;*/
}
.ears-exam-subTitle{
    /*margin-left: 0.5rem;*/
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #2E2E2E;
    margin-bottom: 0.3125rem;
    margin-top: 0.3125rem;

}
.ears-exam-video-player{
    /*margin-left: 0.5rem;*/
    /*width: 25rem;*/
}
.ears-exam-video-container{
 padding-left: 0.5rem;
}
.frame-otoscope-exam{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    isolation: isolate;
    width: 20.5rem;
    height: 20.5rem;
    background: #000000;
    border-radius: 0.625rem;
}
.ears-exam-fullScreen-button{
    /*top: -5.2rem;*/
    left: 19rem !important;
}
.ears-exam-fullScreen-button-mobile{
    left: 16rem !important;
}
.ears-exam-video-image-container{
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-right: 10%;
}
.ear-exam-snapShotImage{
    width: 9.375rem;
    height: 9.375rem;
    border-radius: 10px;
}
.image-trash-btn-snap{
    width: 2.25rem;
    height: 2.25rem;
    cursor: pointer;
    /* width: 15% !important; */
    position: relative;
    bottom: 12rem;
    left: 6.5rem;
}
.ear-exam-capture-container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: stretch;
    margin-left: 1rem;
    row-gap: 1rem;
    margin-bottom: 1rem;
}
.ears-exam-noVideo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 16px;
    gap: 0.75rem;
    width:20.5rem;
    height:20.5rem;
    background: #F5F5F7;
    border: 1px solid #F0EFF2;
    border-radius: 0.625rem;
    font-family: 'Rubik-Regular';
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    text-align: center;
    color: #A7A7A7;
}
.video-player-title{
    font-family: 'Rubik-Light';
    font-style: normal;
    font-size: 1rem;
    text-transform: capitalize;
    color: #FFFFFF;
    text-align: left;
    width: 100%;
    /*padding-left: 0.25rem;*/
}
.stethoscopeExams_subLabel{
    margin-top: 0.625rem;
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #2E2E2E;
}
.exam-skin-image{
    width: 20rem;
    height: 20rem;
    border-radius: 0.625rem;
}
.exam-skin-image-body{
    height: 7.875rem;
    position: relative;
    right: 2rem;
}
.exam-skin-image-enlarge{
    width: 2.25rem;
    position: relative;
    /*top: 42%;*/
    /*right: 6%;*/

}
.exam-ear-image-enlarge{
    width: 2.25rem;
    position: relative;
    bottom: 68%;
    left: 51%;

}
.exam-skin-image-con{
    margin-top: 1rem;
    display: flex;
    align-items: baseline;

}
.media-dialog-snap{
overflow: hidden;
    width: 56.25rem;
    height: 51.625rem;
    border-radius: 0.625rem;
}
.media-dialog-video{
    overflow: hidden;
    width: 56.25rem;
    height: 45.875rem;
    border-radius: 0.625rem;
    padding: 2rem;
}
.media-dialog-snap-small{
    width: 50.25rem !important;
    height: 35.625rem !important;
}
.media-dialog-video-small{
    width: 50.25rem !important;
    height: 43.625rem !important;
}
.media-dialog-snap-content{
    width: 56.25rem;
    height: 51.625rem;
    display: flex;
    border-radius: 0.625rem;
}
.media-dialog-video-content{
    width: 56.25rem;
    height: 40rem;
    display: flex;
    border-radius: 0.625rem;
}
.media-dialog-snap-mobile{
    width: 95% !important;
    height: 60% !important;
}
.media-dialog-video-mobile{
    width: 97% !important;
    height: 65% !important;
}
.media-dialog-main-video-mobile{
    width: 97% !important;
    height: 65% !important;
}
.media-dialog-main-video-mobile-content{
    width: 97% !important;
    height: 27rem !important;
}
.media-dialog-snap-image{
    height: 100%;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}
.media-dialog-ear-video{
    width: 100%;
    height: 70%;
    background-color: #000000;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
}
.media-dialog-snap-title{
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    font-family: Rubik-Light;
    font-style: normal;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 1.875rem;
    color: #FFFFFF;
    background-color: #000000;
    height: 5.875rem;
    width: 100%;
    border-top-left-radius:0.625rem ;
    border-top-right-radius:0.625rem ;
}
.media-dialog-snap-title-mobile{
    font-size: 1rem !important;
    padding-left: 1rem !important;

}
.media-dialog-snap-modal-close-btn{
    width: 6.25rem;
    height: 6.25rem;
    position: absolute;
    left: 92%;
}
.media-dialog-snap-modal-close-btn-mobile{
    width: 4.25rem !important;
    height: 4.25rem !important;

}
.brightnessFilterContainerNew{
    display: flex;
    width: 96%;
    height: 3.625rem;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 1rem;
    position: relative;
    flex-direction: row;
    bottom: 2%;
    margin-left: 1rem;
    padding-right: 1rem;

}
.brightnessFilterNew{
    display: flex;
    flex-direction: row;
    align-items: center;
    /*padding: 0px 10px;*/
    /*justify-content: space-between;*/
    /*gap: 20px;*/
    width: 40%;
    height: 3.725rem;
    background: #2E2E2E;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6.25rem;
}
.frameFilterNew{
    /*width:16.125rem !important;*/
    height: 3.625rem;
    padding: 4px !important;
    display: grid;
    grid-template-columns: auto auto;
    align-content: center;
}
.frameFilterWhiteNew{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.5rem;
    background: #FFFFFF;
    border-radius: 2rem;
    font-family: Rubik-Regular;
    color: #2E2E2E;
    text-align: center;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.ears-exam-path{
    width: 100%;
    height: 1px;

    /* main/border */

    border-bottom: 1px solid #EEE9F8;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
}