.OutLinedRangeSelect__indicator svg path {
    fill: white;
}

/*React-select*/
.outlined-time-select-container, .outlined-date-select-container, .outlined-custom-input-container {
    position: relative;
    z-index: 100;
    height: 56px !important;
}

.outlined-date-select-container{
    z-index: 1000 !important;
}

.outlined-select-date .SingleDatePicker,.outlined-select-date .SingleDatePickerInput,.outlined-select-date .DateInput  {
    width: 100%;
    display: flex;
    /*height: 100%;*/
    border: 0;
}
.SingleDatePicker_picker .SingleDatePicker_picker_1 .SingleDatePicker_picker__directionLeft .SingleDatePicker_picker__directionLeft_2{
    left: 20px !important;

}
.SingleDatePicker_picker__directionLeft{
    left: 20px !important;
}
.outlined-date-select outlined-date-select-focused{
    z-index: 1000000;
}
.outlined-date-select-container .DateInput_input{
    width: 100%;
    z-index: 100;
    text-align: left!important;
}
.outlined-date-select-container .DateInput_input::placeholder{
    visibility: hidden;
}
.outlined-phone{
    position: relative;
}
.OutLinedRangeSelect-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.oOutLinedRangeSelect-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}
.outlined-select-labelPhone{
    position: absolute;
    left: 60px;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.OutLinedRangeSelect-label-focused, .outlined-select-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 16px;
}

.OutLinedRangeSelect {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.OutLinedRangeSelect-focused {
    border: 3px solid #4c23b5;
}

.OutLinedRangeSelect-label-err {
    color: #fd5a56 !important;
}

.OutLinedRangeSelect-label-disabled {
    color: #A590D9 !important;
}

.OutLinedRangeSelect-err {
    border: 3px solid #fd5a56 !important;
}

.OutLinedRangeSelect-disabled {
    border: 3px solid #e8e9ec !important;
}

/*inside select*/
.OutLinedRangeSelect__option {
    color: #2e2e2e !important;
    cursor: pointer !important;
}

.OutLinedRangeSelect__option--is-focused {
    background-color: rgba(76, 35, 181, 0.2) !important;
}

.OutLinedRangeSelect__option--is-selected {
    background-color: #f7f7f9 !important;

}

.OutLinedRangeSelect__option--is-selected.OutLinedSelect__option--is-focused {
    background-color: rgba(76, 35, 181, 0.1) !important;
}

.OutLinedRangeSelect__indicator-separator {
    background-color: rgba(76, 35, 181, 0.1) !important;
    margin: 0 !important;
}

.OutLinedRangeSelect-err.OutLinedSelect__indicator-separator {
    background-color: #fd5a56 !important;
    margin: 0 !important;
}

.OutLinedRangeSelect-disabled.OutLinedSelect__indicator-separator {
    background-color: #e8e9ec !important;
    margin: 0 !important;
}

.OutLinedRangeSelect__indicator svg {
    margin-right: -25px;
    margin-top: -10px;
}

.OutLinedRangeSelect__indicator svg path {
    fill: white !important;
}

.OutLinedRangeSelect-err.OutLinedSelect__indicator svg path {
    fill: #fd5a56 !important;
}

.OutLinedRangeSelect-disabled.OutLinedSelect__indicator svg path {
    fill: #e8e9ec !important;
}

.OutLinedRangeSelect__indicators {
    width: 70px;
    cursor: pointer;
    justify-content: space-around;
    /*border-left: 2px rgba(76, 35, 181, 0.3) !important;*/
}

.OutLinedRangeSelect__menu {
    z-index: 1002 !important;
}
.filledSelectInput{
    color:#ffffff !important;
}
.filledSelectInput .OutLinedSelect__input{
    color:#ffffff !important;
}
/*React-datez*/
.outlined-date-select-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.outlined-date-select-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    /*z-index: 1001;*/
}

.outlined-date-select-label-focused, .outlined-date-select-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 0.75rem;
}

.outlined-date-select {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.outlined-date-select-focused {
    border: 3px solid #4c23b5;
}

.outlined-date-select-label-err {
    color: #fd5a56 !important;
}

.outlined-date-select-label-disabled {
    color: #A590D9 !important;
}

.outlined-date-select-err {
    border: 3px solid #fd5a56 !important;
}

.outlined-date-select-disabled {
    border: 2px solid #EEE9F8 !important;
    background: white;
    color: #2E2E2E !important;
}

.OutLinedDateSelect {
    height: 100% !important;
    width: 100%;
}

.OutLinedDateSelect-input {
    height: 100% !important;
    width: 100%;
    outline: unset !important;
    border: unset !important;
    padding: 2px 43px 2px 8px;
}

.OutLinedDateSelect .cal-icon {
    height: 22px !important;
    width: 22px !important;
}

.OutLinedDateSelect-err .cal-icon g path {
    fill: #fd5a56 !important;

}

/*Time Select*/
.outlined-time-select-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.outlined-time-select-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.outlined-time-select-label-focused, .outlined-time-select-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 16px;
}

.outlined-time-select {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.outlined-time-select-focused {
    border: 3px solid #4c23b5;
}

.outlined-time-select-label-err {
    color: #fd5a56 !important;
}

.outlined-time-select-label-disabled {
    color: #A590D9 !important;
    background-color: transparent;
}

.outlined-time-select-err {
    border: 3px solid #fd5a56 !important;
}

.outlined-time-select-disabled {
    border: 3px solid #e8e9ec !important;
}

.OutLinedTimeSelect {
    height: 100% !important;
    width: 100%;
}

.OutLinedTimeSelect-input {
    height: 100% !important;
    width: 100%;
    outline: unset !important;
    border: unset !important;
    padding: 2px 43px 2px 8px;
}

.OutLinedTimeSelect .cal-icon {
    height: 22px !important;
    width: 22px !important;
}

.OutLinedTimeSelect-err .cal-icon g path {
    fill: #fd5a56 !important;

}

.outlined-time-select .react-datepicker-wrapper, .outlined-time-select .react-datepicker-wrapper .react-datepicker__input-container, .OutLinedTimeSelect {
    border: unset !important;
    height: 100% !important;
    width: 100% !important;
}

.OutLinedTimeSelect {
    padding: 2px 43px 2px 8px;
    border: unset !important;
    outline: unset !important;
    cursor: pointer;
}

.OutLinedTimeSelect-popper {
    position: relative !important;
    margin-top: -50px !important;
    margin-bottom: 0 !important;
    transform: translate3d(0px, 50px, 0px) !important;
    width: 100%;
}

.react-datepicker__time-list-item--disabled {
    display: none !important;
}

.react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only, .react-datepicker__triangle {
    display: none !important;
}

.react-datepicker__tab-loop, .react-datepicker-popper, .react-datepicker--time-only, .react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box {
    width: 100% !important;
}

.react-datepicker__time-list-item {
    font-family: Rubik-Regular;
    font-weight: normal !important;
    font-size: 18px;
    color: #2e2e2e !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px !important;
    border: unset !important;
    outline: unset !important;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: rgba(76, 35, 181, 0.1) !important;


}

.outlined-error {
    font-size: 12px;
    color: #fd5a56;
    padding-left: 10px;
}


/*Input*/
.outlined-custom-input-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}
.outlined-custom-input-labelPhone {
    position: absolute;
    /*font-family: Roboto;*/
    left: 60px !important;
    top: -5px !important;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.outlined-custom-input-label-default {
    position: absolute;
    left: 0;
    font-size: 17px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.outlined-custom-input-label-focused, .outlined-custom-input-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 16px;
}

.custom-input {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    padding: 0 12px;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.custom-input-focused {
    border: 3px solid #4c23b5;
}

.outlined-custom-input-label-err {
    color: #fd5a56 !important;
}

.outlined-custom-input-label-disabled {
    color: #A590D9 !important;
    background-color: transparent;
}

.outlined-custom-input-label-valued-disabled {
    color: #A590D9 !important;
    background-color: white;
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 0.75rem;
    width: auto !important;
}

.custom-input-err {
    border: 3px solid #fd5a56 !important;
}

/*.custom-input-disabled {*/
/*    border: 3px solid #e8e9ec !important;*/
/*    background: white;*/
/*    color: hsl(0, 0%, 60%);*/
/*}*/

.custom-input {
    height: 100% !important;
    width: 100%;
}

/*.custom-input {*/
/*    height: 100% !important;*/
/*    width: 100%;*/
/*    outline: unset !important;*/
/*    border: unset !important;*/
/*    padding: 2px 43px 2px 8px;*/
/*}*/

.custom .cal-icon {
    height: 22px !important;
    width: 22px !important;
}

.custom-err .cal-icon g path {
    fill: #fd5a56 !important;

}

.custom-input .react-datepicker-wrapper, .custom-input .react-datepicker-wrapper .react-datepicker__input-container, .custom {
    border: unset !important;
    height: 100% !important;
    width: 100% !important;
}

.custom {
    padding: 2px 43px 2px 8px;
    border: unset !important;
    outline: unset !important;
    cursor: pointer;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: rgba(76, 35, 181, 0.1) !important;


}

.outlined-error {
    font-size: 12px;
    color: #fd5a56;
    padding-left: 10px;
}
.CalendarDay__selected_span {
    background: rgba(76, 35, 181, 0.53) !important;
    color: white !important;
    border: 1px solid #8971c3 !important;
}
.DateInput_input{
    font-family: Rubik-Regular !important;
    font-size: 0.9vw;
    color: #2e2e2e !important;
    padding: 14px 2px 9px !important;
    text-align: center !important;
}
.DateInput_input__focused{
    border-bottom: none !important;

}

.CalendarDay__selected {
    background: #4c23b5 !important;
    color: white !important;
    border: 1px solid #8971c3 !important;
}


.CalendarDay__selected:hover {
    background: #4c23b5 !important;
    color: white !important;
    color: white !important;
    border: 1px solid #8971c3 !important;
}


.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: rgba(76, 35, 181, 0.53) !important;
    color: white !important;
    border: 1px solid #8971c3 !important;
}
.outlined-date-range-select{
    border: 1px solid transparent !important;
    position: relative;
    border-radius: 5px;
    height: 54px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 1rem;
    bottom: 2px;
    border-radius: 4px !important;
}
.DateRangePickerInput__withBorder{
    border-radius: 4px !important;
    height: 55px !important;
    border: 3px solid rgba(76, 35, 181, 0.5) !important;

}
.yearMonthSelect{
    border: 3px solid rgba(76, 35, 181, 0.5) !important;
    height: 30px !important;
    width: 100% !important;
}
.clearDate{
    border: none;
    text-decoration: underline;
    background-color: transparent;
    color: rgba(76, 35, 181, 0.5);
    font-size: 14px;
    float: right;
}
.yearSelect{
    width: 29%;
    margin-right: 5px;
}
.monthSelect{
    width: 45%;
}
.DateInput_input .DateInput_input_1{
    background: unset !important;
}
.DateInput_input{
    background: unset !important;
}
.calendarPath{
    height: 50px;
    align-self: stretch;
    border-left: solid 0.5px #eee9f8;
    background-color: #fff;
    width: 0;
    position: relative;
    left: -7px;
    bottom: 10px;
}
.calendarIconContainer{
    background: unset;
    display: flex;
    height: 30px;
}
.calendarIconContainer img{
    position: relative;
    left: 4px;
    top: 2px;
}
.calendarIconContainerSingle{
    background: unset;
    display: flex;

}
.calendarIconContainerSingle img{
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    height: 20px;

}
.SingleDatePickerInput_calendarIcon{
    width: 0!important;
    height: 0!important;
    padding: 0;
    margin: 0;
    display: none!important;
}
@media (min-width: 375px) and (max-width:768px){
    .SingleDatePickerInput_calendarIcon{
        padding: 0 !important;
    }
}
.SingleDatePickerInput__disabled ,.DateInput__disabled{
    background-color: #FFFFFF !important;
}