
.sidebar-Container{
    margin-left: 1rem;

}
.sidebar-Container-small{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}
.sidebar-medium-large-screen{
    /*padding-top: 2.125rem;*/
    margin-bottom: 5rem;
}
.sidebar-text{
    font-family: 'Rubik-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #2E2E2E;
}
.sidebar-examCard{
    border-bottom: 1px solid #F0EFF2;
    height: auto;
    min-height: 5rem;
    width: 100%;
    margin-bottom: 1rem;
    cursor: pointer;
}
.sidebar-title-container{
    display: flex;
    align-items: center;


}
.sidebar-examTitle{
    margin-left: 1rem;
}
.sidebar-examDate{
    margin-top: 1rem;
    margin-bottom: 2.4375rem;
}
.sidebar-examValue{
    font-weight: 700;
    font-size: 1.375rem;
    width: 80%;
    display: flex;
    justify-content: start;
    margin-top: 0.6875rem;
    margin-left: 2.875rem;
    margin-bottom: 1.5rem;
}
.sidebar-exam-icon{
    width: 1.875rem;
    height: 1.875rem;

}

.sidebar-exam-icon-mobile{

    width: 1.65rem;
    height: 1.65rem;
    /*width: 20.8px;*/
    /*height: 20.8px;*/
}
.sidebar-exam-image{
    border-radius: 0.5rem;
    width:3.125rem;
    height: 3.125rem;
    /*border: 1px solid;*/
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

}
.sidebar-exam-image-container{
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 1rem;
}
.sidebar-exam-subTitle{
    color: #A7A7A7;
    font-size: 0.75rem;
    font-weight: 400;
    margin-left: 0.20rem;
    margin-bottom: 0.125rem;
}
.sidebar-exam-subTitle-video{
    margin-bottom: -0.875rem !important;

}

.sidebar-exam-video-play {
    width: 1.4375rem;
    height: 1.4375rem;
    position: relative;
    left: 0.8rem;
    top: 2.2rem;
    display: block;
}
.sidebar-exam-video{
    background-color: #2E2E2E;
}
.sidebar-lungs-container{
    display: flex;
}
.sidebar-lungs-points{
    margin-right: 1rem;
}

.sidebar-bloodPressure-main-text{
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: left;

}
.sidebar-bloodPressure-main-text-main{
    font-size: 1.625rem !important;
    font-weight: 500 !important;
}
.sidebar-bloodPressure-text{
    font-size: 0.75rem !important;
    width:2.375rem;
    color: #2E2E2E;
    font-weight: 400;
    margin-left: 0.5rem;
}
.sidebar-bloodPressure-text-main{
    font-size: 0.75rem !important;
    color: #2E2E2E;
    font-weight: 400;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
}
.sidebar-icons-tablet{
    display: flex;
    justify-content: center;
    width:100vw;
    /*overflow-x:auto;*/
}
.sidebar-icons-extraSmall{

}
.sidebar-icons{
    height: 2.5rem;
    width: auto;
}
.sidebar-icons-container{
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    background-color: #FFFFFF;
    border: 1px solid #F0EFF2;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    margin-right: 1.125rem;
    cursor: pointer;

}

.sidebar-icons-container-saturation:after{
    bottom: 3rem;
    left: 2rem;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #7B5EC6;
    /* position: inherit; */
    display: inline-block;
    position: relative;
    z-index: 5;
}
.sidebar-icons-mobile{
    padding-left:7rem !important;
}
.share-records-button{
    width: 9.25rem!important;
    justify-content: space-between !important;


}
.prescription-required{
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.688rem;
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    color: #7B5EC6;
}
.call-patient-button-container{
    display: flex;
    height:2.5rem;
    /*padding: 8px 16px;*/
    padding-left: 8px;
    padding-right: 18px;
    /*justify-content: center;*/
    align-items: center;
    /*gap: 12px;*/
    align-self: stretch;
    margin-top: 1.5rem;
}

.call-patient-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15.5rem;
    height:2.5rem;
    background-color:#68BE0B ;
    border-radius: 100px;
    color:#FFF;
    text-align: center;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    border: 1px solid transparent;
}
.call-patient-button-mobile{
    width: 2.5rem;
    height:2.5rem;
}