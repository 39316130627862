.exam-result-container {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat, repeat;
    background-size: 100% 140%;
    position: relative;
}

.temp-exam-container {
    background-image: url("../../../../assets/background/temp.svg");

}

.saturation-exam-container {
    background-image: url("../../../../assets/background/saturation.svg");

}

.exam-res-container {
    width: max-content;
    display: flex;
    align-items: center;
    height: 100%;
}

.exam-container.select {
    background-image: url("../../../../assets/background/select.svg");
    background-repeat: no-repeat, repeat;
    background-size: 100% 140%;
}

.heart-exam-container {
    background-image: url("../../../../assets/background/heart.svg");
}

.abdomen-exam-container {
    background-image: url("../../../../assets/background/abdomen.svg");
}

.lungs-exam-container {
    background-image: url("../../../../assets/background/lungs.svg");
}

.ears-exam-container {
    background-image: url("../../../../assets/background/ears.svg");

}

.throat-exam-container {
    background-image: url("../../../../assets/background/throat.svg");
    display: flex;
    margin-left: 2%;


}
.throat-mouth-exam-container {
    background-image: url("../../../../assets/background/throat.svg");
    display: flex;

}
.throat-exam-results-container video::-webkit-media-controls-fullscreen-button{
    visibility: hidden;
}
.throat-exam-results-container-throat video::-webkit-media-controls-fullscreen-button{
    visibility: hidden;
}
.throat-exam-results-container-ears video::-webkit-media-controls-fullscreen-button{
    visibility: hidden;
}
.mouthThroat-video video::-webkit-media-controls-fullscreen-button{
    visibility: hidden;
}


/*.grid-item-throat{*/
/*    background-color: rgba(255, 255, 255, 0.8);*/
/*    border: 1px solid rgba(0, 0, 0, 0.8);*/
/*    padding: 20px;*/
/*    font-size: 30px;*/
/*    text-align: center;*/
/*}*/
.skin-exam-container {
    background-image: url("../../../../assets/background/skin.svg");

}

.temp-results-container, .saturation-results-container {
    height: max-content;
    width: max-content;
    display: flex;
    align-items: baseline;
}

.all-exam-results-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.saturation-results-container {
    justify-content: space-between;
}

.exam-temperature {
    font-size: 96px;
    color: #f8a315;
    font-family: Rubik-Light;
}

.exam-temperature-units-container {
    display: flex;
}

.exam-temperature-unit {
    font-family: Rubik-Light;
    color: #5e5e5e;
    font-size: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.exam-temperature-unit:nth-last-child(n+2) {
    border-right: 1px solid #5e5e5e;
}

.exam-temperature-unit-active {
    color: #f8a315;
}

.exam-saturation {
    color: #05d2ce;
    font-size: 96px;
}

.bpm-result {
    display: flex;
    align-items: baseline;
}

.exam-saturation:nth-last-child(n+2) {
    margin-right: 95px;
}

.bpm-result {
    display: flex;
}

.bpm-result-unit {
    font-size: 30px;
    margin-left: 20px;
}

.skin-exam-container {
    display: flex;
    justify-content: space-between;
}

.skin-exam-body-container {
    height: 80%;
    padding-left: 50px;
    padding-right: 50px;
    border-right: 2px solid rgba(255, 123, 74, 0.3);
    width: max-content;
}

.skin-exam-body {
    height: 120%;
    float: right;
}

.skin-exam-images-container {
    height: 100%;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skin-image-container {
    margin: 0 15px;
}

.skin-image {
    width: 180px;
    height: 230px;
    border-radius: 18px;
}

@media (min-width:400px) and (max-width:1700px) {
    .skin-exam-results-container{
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }
    .skin-exam-images-container{
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-items: center;
    }
    .skin-image-title{
        max-width: 80% !important;
        font-size: 0.8vw !important;
    }
    .skin-image{
        height: 180px;
        width: 90%;
    }
    .skin-image-container:hover .skin-image{
        width: 90% !important;
        height: 180px !important;

    }
}


.skin-image-img {
    height: 100%;
    width: 100%;
    border-radius: 18px;
}

.skin-exam-results-container {
    height: 100%;
    flex-grow: 1;
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
}

.throat-exam-results-container {
    height: 100%;
    flex-grow: 1;
    width: max-content;
    /* padding-left: 50px; */
    position: relative;
    /*left: -3%;*/
    padding-right: 50px;
    display: flex;
    margin-top: 40px;
    cursor: pointer;
}

.throat-exam-results-container-throat {
    display: grid;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 33% 33% 33% 33% 33%;
    grid-gap: 4% 3%;
    width: 39%;
    margin-top: 39px;
    position: relative;
    right: 4%;
    margin-right: 80px;
    cursor: pointer;
}
.throat-exam-results-container-throat-online{
    display: grid;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 33% 33% 33% 33% 33%;
    grid-gap: 4% 3%;
    width: 39%;
    margin-top: 39px;
    position: relative;
    right: 4%;
    margin-right: 80px;
}
.throat-exam-results-container-ears{
    display: grid;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 33% 33% 33% 33% 33%;
    grid-gap: 4% 3%;
    width: 65%;
    margin-top: 39px;
    position: relative;
    margin-left: 36px;
}
.throat-exam-results-container-ears-online{
    display: grid;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 33% 33% 33% 33% 33%;
    grid-gap: 4% 3%;
    width: 65%;
    margin-top: 39px;
    position: relative;
    margin-left: 36px;
}

.ears-exam-results-container-ears {
    display: grid;
    grid-template-columns: repeat(3,31%);
    grid-template-rows: repeat(2,49%);
    grid-gap: 4% 3%;
    width: 50%;
    position: relative;
}
.throat-grid-item-capture{
    border-radius: 16px;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
}

.throat-grid-item-capture-online{
    border-radius: 16px;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;

}
.throat-grid-item-capture-online-0{
    border-radius: 16px;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
    cursor: pointer;
}
.throat-grid-item-capture-online-1{
    border-radius: 16px;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-column-end: 3;
}
.throat-grid-item-capture-online-2{
    border-radius: 16px;
    grid-row-start: 2;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 4;
}
.throat-grid-item-capture-online-3{
    border-radius: 16px;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
}
.throat-grid-item-capture-online-4{
    border-radius: 16px;
    grid-row-start: 3;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 3;
}
.throat-grid-item-capture-0{
    border-radius: 16px;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 2;
}
.throat-grid-item-capture-1{
    border-radius: 16px;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-column-end: 3;
}
.throat-grid-item-capture-2{
    border-radius: 16px;
    grid-row-start: 2;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 4;
}
.throat-grid-item-capture-3{
    border-radius: 16px;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
}
.throat-grid-item-capture-4{
    border-radius: 16px;
    grid-row-start: 3;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 3;
}
.throat-grid-item{
    border-radius: 16px;
    width: 18vw;
    height: 38vh;
}
/*.exam_title_throat{*/
/*    font-weight: 700;*/
/*    font-size: 14px;*/
/*    line-height: 14px;*/
/*    color: #3F2E6B;*/
/*    margin-left: 5%;*/
/*    margin-bottom: 2%;*/
/*}*/
.exam_title_throat{
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #3F2E6B;
    margin-bottom: 4%;

}
.exam_title_Ear{
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #3F2E6B;
    margin-left: 2%;
    margin-bottom: 10px;
    margin-top: 15px;

}
.throatMouth-title{
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #4C23B5;
    position: relative;
    /* margin-left: 5px; */
    /* left: 4%; */
    /*height: 30px;*/
}
.newthroatMouth-title{
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #4C23B5;
    position: relative;
     left: 6%;
    /*height: 30px;*/
}
.ears-title{
    left: 1%;
    width: 17%;
    display: block;
}
.exam_footer_throat{
    font-weight: 400;
    font-size: 0.875rem;
    color: #7F7F7F;
    margin-left: 2%;
    margin-top: 3%;
}
.Error-Files {
    font-size: 26px;
    color: red;
}

.skin-image-title {
    font-family: Rubik-Medium;
    color: #ff7b4a;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    visibility: hidden;
}

.skin-image-number {
    font-family: Rubik-Bold;
    color: #000;
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
    visibility: hidden;
}

.skin-image-container:hover .skin-image-title, .skin-image-container:hover .skin-image-number {
    visibility: visible;
}

.skin-image-container:hover .skin-image {
    border: solid 4px #ff7b4a;
    background-color: #ff7b4a;
    width: 210px;
    height: 260px;
}

.ears-exam-images-container {
    display: flex;
    justify-content: space-between;
    width: max-content;

}

.throat-exam-images-container {
    display: flex;
    justify-content: space-between;
    width: max-content;

}

.exam-body {
    height: 90%;
    width: auto;

}

.ap-contContainer-heart-exam-player {
    border-color: rgba(253, 90, 86, 0.3) !important;
    background-color: rgba(255, 239, 239, 0.73);
}

.ap-heart-exam-player path {
    fill: #fd5a56 !important;
}

.ap-range-heart-exam-player::-webkit-slider-thumb {
    background-color: #fd5a56;
}

.ap-range-heart-exam-player::-webkit-slider-runnable-track {
    background-color: rgba(253, 90, 86, 0.3);
}

.stethoscope-audios {
    height: 260px;
    border-left: 1px solid #fd5a56;
    padding-left: 50px;
}

.dot-audio-container-online {

}

.dot-audio-container .ap-cont-heart-exam-player.stethoscope-audio:nth-last-child(n+2) {
    margin-bottom: 0px;
}

.ap-cont-heart-exam-player.stethoscope-audio:nth-last-child(n+2) {
    margin-bottom: 30px;
}

.ap-contContainer-abdomen-exam-player {
    border-color: rgba(193, 0, 150, 0.3) !important;
    background-color: rgba(249, 230, 245, 0.73);
}

.ap-abdomen-exam-player path {
    fill: #c10096;
}

.ap-range-abdomen-exam-player::-webkit-slider-thumb {
    background-color: #c10096;
}

.ap-range-abdomen-exam-player::-webkit-slider-runnable-track {
    background-color: rgba(193, 0, 150, 0.3);
}

.stethoscope-audios {
    height: 260px;
    border-left: 1px solid #c10096;
    padding-left: 50px;
}

.ap-cont-abdomen-exam-player.stethoscope-audio:nth-last-child(n+2) {
    margin-bottom: 30px;
}

.ap-contContainer-lungs-exam-player {
    border-color: rgba(0, 133, 255, 0.3) !important;
    background-color: #f2f9ff;
}

.ap-lungs-exam-player path {
    fill: #0085ff;
}

.ap-range-lungs-exam-player::-webkit-slider-thumb {
    background-color: #0085ff;
}

.ap-range-lungs-exam-player::-webkit-slider-runnable-track {
    background-color: rgba(0, 133, 255, 0.3);
}

.stethoscope-audios {
    height: 260px;
    border-left: 1px solid #0085ff;
    padding-left: 50px;
}

.ap-cont-lungs-exam-player.stethoscope-audio:nth-last-child(n+2) {
    margin-bottom: 30px;
}

.lungs-face-options {
    height: 60%;
}

.lungs-face-option {
    font-family: Rubik-Light;
    font-size: 1.3vw;
    color: rgba(0, 133, 255, 0.5);
    margin: 15px 20px;
}

.lungs-face-option-active {
    font-family: Rubik-Medium;
    color: #0085ff;
}
.snapShotImage{
    width: 86%;
    height: 81%;
    padding: 10px;
    background-color: black;
    border-radius: 16px;


}
.throat-mouth-container{

}
.image-close-btn-video{
    position: relative;
    bottom: 90%;
    left: 70%;
    width: 10% !important;
    cursor: pointer;
}
.image-trash-btn-video{
    cursor: pointer;
    width: 10% !important;
    position: relative;
    bottom: 100%;
    left: 70%;
}
.image-trash-btn-video-online{
    cursor: pointer;
    width: 10% !important;
    position: relative;
    bottom: 100%;
    left: 70%;
}
.image-trash-btn-image{
    cursor: pointer;
    width: 10% !important;
    position: absolute;
    bottom: 94%;
    left: 70%;
}
