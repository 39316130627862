
.session-data-container {
    min-height: 935px;
    height: max-content;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}
.permissions-header{
    height: 80px;
    background-color: #3f2e6b;
    width: 100%;
    top: 0;
    font-family: Rubik-Regular;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}
.permissions-header-controller{
    display: flex;
    width: max-content;
    align-items: center;

}
.enable-media-btn{
    height: 45px;
    background-color: white;
    margin-right: 40px;
    color: #30352f!important;
    font-family: Rubik-Bold;
}
.enable-media-btn:hover {
    color: #fff!important;
}
.permissions-header-svg{

}
.patient-card-content-container:nth-last-child(n+2) {
    margin-bottom: 25px;
    border-radius: 10px;
}
.patient-info-row-options{
    float: right;
    width: 5%;
}
.patient-personal-data-holder {
    display: flex;
    justify-content: left;
    max-width: 345px;
    width: max-content !important;
}

.patient-personal-label {
    color: rgba(0, 0, 0, 0.5);
}

.col1 {
    width: 90px;
    margin-right: 22px;
}

.col2 {
    width: 105px;
}

.col3 {
    width: 160px;
}

.patient-personal-value {
    color: #30352f;
    width: max-content;
}
.input-width{
    width: 50px;
}

.patient-call-btn .patient-call-btn-videocam {
    height: 17px;
    width: auto;
}



.patient-call-btn.patient-call-btn-flicker{
    -webkit-animation: flickerAnimation .8s infinite;
    -moz-animation: flickerAnimation .8s infinite;
    -o-animation: flickerAnimation .8s infinite;
    animation: flickerAnimation .8s infinite;
}

.patient-call-btn:disabled .patient-call-btn-text {
    color: #c4c4c4;
}
.OT_root, .OT_root *{
    border-radius: 20px !important;
}
.tokbox-mobile-small .OT_root, .OT_root *{
    border-radius: 0px !important;
}
.patient-call-btn .patient-call-btn-videocam path {
    fill: #ffffff;
}

.patient-call-btn:disabled .patient-call-btn-videocam path {
    fill: #c4c4c4;
}

.session-header-container {
    padding: 10px 20px;
}

.session-header {
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    border-bottom: 2px solid #ececec;
}

.session-header-time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.session-header-time-container:nth-last-child(n+2) {
    margin-right: 40px;
}

.session-header-date {
    margin-right: 10px;
}

.session-header-text {
    /*font-family: Rubik-Light;*/
    font-size: 1.25vw;
    color: #2717a4;
}

.online-Session-indicator {
    height: 10px;
    width: 10px;
    background-color: #05d2ce;
    border-radius: 50%;
}

.online-session-header-text {
    font-size: 24px;
    color: #05d2ce;
}

.condition-description-container {
    max-height: 100px;
    /*height: max-content;*/
    display: flex;
    padding: 10px 20px;
    min-height: 70px;
}

.condition-description-label {
    min-width: 200px;
    width: max-content;
    font-family: Rubik-Bold;
}

.condition-description-play {
    height: 50px;
    width: 50px;
    margin-right: 20px;
}

.condition-description-video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 150px;
}

.condition-description-text {
    font-family: Rubik-Light;
    height: inherit;
    overflow-y: auto;
    margin: 0 100px;
    flex-grow: 1;
    padding: 0 35px;
}

.condition-description-play-text {
    min-width: 80px;
    color: #000000;
    font-family: Rubik-Medium;
    width: max-content;
}

.session-exams-container {
    height: max-content;
    padding: 10px 20px;
    width: 100%;
}

.session-save-container {
    height: 80px;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.session-summary-container {
    height: 130px;
    padding: 10px 20px;
    width: 100%;
}

.session-save-btn {
    /*padding: 10px;*/
    width: 190px;
}

.session-summary {
    height: 100%;
    width: 50%;
    resize: none;
    border-radius: 10px;
    border: solid 1px rgba(76, 35, 181, 0.1);
    background-color: #f7f7f9;
    outline: unset;
    padding: 5px 10px;
    font-size:0.729vw;
    color: rgba(76, 35, 181, 0.5);
    padding: 18px;
}
::placeholder{
    font-size: 14px;
    color: rgba(76, 35, 181, 0.5);
}
.session-internalSummary {
    padding: 18px;
    margin-left: 1%;
    height: 100%;
    width: 49%;
    resize: none;
    border-radius: 10px;
    border: solid 1px rgba(76, 35, 181, 0.1);
    background-color: #f7f7f9;
    outline: unset;
    font-size:0.729vw;
    color: rgba(76, 35, 181, 0.5);
}

.session-tabs-container {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.session-tab-icon {
    height: 33px;
    width: 33px;
    margin-right: 3px;
}

.session-tab {
    flex: 1;
    border-bottom: 2px solid #ececec;
}

.active-session-tab {
    display: flex;
    justify-content: center;
    position: relative;
}

.remote-exam-return-button-container {
    position: absolute;
    left: 0;
}

.remote-exam-return-button {
    height: 30px;
    width: 30px;
}

.remote-exam-return-button:hover g path {
    opacity: 0.7;
}

.session-tab-container {
    display: flex;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: -1px;
}

.session-tab-container.blocked .session-tab-title {
    color: #e2e2e2;
}

.session-tab-title-active {
    font-family: Rubik-Bold;
}

/*.exam-container {*/
/*    width: 100%;*/
/*    height: 600px;*/
/*    padding: 20px 0;*/
/*    border-bottom: 2px solid #ececec;*/
/*}*/
.exam-container {
    width: 100%;
    height: 780px;
    padding-top: 20px;
    padding-bottom: 60px;
    border-bottom: 2px solid #ececec;
}

.session-suggestions-container {
    width: 100%;
    height: max-content;
    display: flex;
    font-size: 0.8vw;
    position: relative;
    /*align-items: center;*/
}

.quick-suggestions {
    width: max-content;
    height: max-content;
    /*display: flex;*/
    /*justify-content: space-around;*/
}

.quick-suggestions-label-container {

}

.quick-suggestions-label {
    color: rgba(76, 35, 181, 0.5);
    margin-right: 15px;
    min-width: 150px;
    position: relative;
    top: 8px;
    height: 100%;
}

.quick-suggestion {
    border-radius: 25px;
    margin-bottom: 10px;
    background-color: rgba(76, 35, 181, 0.05);
    padding: 8px 15px;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 15px;
}
.summaryForPatients{
    display:flex ;
    fontWeight:bold;
    fontSize:0.8vw;
    color:#2e2e2e;
}
.sdsds {
    height: 100%;
    width: 22px;
}

.choose-exam-label {
    font-family: Rubik-Light;
    font-size: 35px;
    color: #000000;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    text-align: center;
}
