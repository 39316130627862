/*React-select*/
.outlined-time-select-container, .outlined-select-container, .outlined-date-select-container, .outlined-custom-input-container {
    position: relative;
    height: 56px !important;
}


.rdatez-calendar-title {
    font-weight: 800;
    text-align: center;
    padding: 5px 0 5px 0px !important;
    margin-bottom: 5px;
}

.outlined-select-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}
.filled-select-label{
    flex-grow: 0;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #d2c8ec;
    padding-left: 10px;

}

.outlined-select-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
}
.filled-select-label-default {
    position: absolute;
    left: 10px;
    font-size: 19px;
    top: 50%;
    transform: translateY(-70%);
    z-index: 1001;
}

.outlined-select-label-focused, .outlined-select-label-active {
    z-index: 5;
    top: 0;
    transform: translateY(-50%);
    font-size: 0.75rem;
}

.filled-select-label-focused, .filled-select-label-active {
    background-color: transparent;
    display: none;
}
.outlined-select-labelPhone-focused, .outlined-select-labelPhone-active {
    z-index: 1000;
    top:-15px;
    left:60px;
    transform: translateY(-50%);
    font-size: 0.75rem;
}

#filled-select-container .css-qc6sy-singleValue{
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.outlined-select {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}
.outlined-PhoneInput {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
}
.outlined-selectRange {
    position: relative;
    /*border-radius: 5px;*/
    height: 40px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 14px;
    border:  1px solid rgba(145, 148, 206, 0.2);
}

.outlined-select-focused {
    border: 3px solid #4c23b5;
}
.outlined-select-focusedPhone {
    border: 0px solid #4c23b5;
}
.outlined-selectRange-focused {
    border: 1px solid rgba(145, 148, 206, 0.2);
}

.outlined-select-label-err {
    color: #fd5a56 !important;
}

.outlined-select-label-disabled {
    color: #A590D9 !important;
}

.outlined-select-err {
    border: 3px solid #fd5a56 !important;
}

.outlined-select-disabled {
    border: 2px solid #EEE9F8 !important;
    background: white;
    color: #2E2E2E !important;
}

/*inside select*/
.OutLinedSelect__option {
    color: #2e2e2e !important;
    cursor: pointer !important;
}
.OutLinedSelectRange__option {
    color: #9194ce !important;
    cursor: pointer !important;
}

.OutLinedSelect__option--is-focused {
    background-color: rgba(76, 35, 181, 0.2) !important;
}

.OutLinedSelect__option--is-selected {
    background-color: #f7f7f9 !important;

}

.OutLinedSelect__option--is-selected.OutLinedSelect__option--is-focused {
    background-color: rgba(76, 35, 181, 0.1) !important;
}

.OutLinedSelect__indicator-separator {
    background-color: rgba(76, 35, 181, 0.1) !important;
    margin: 0 !important;
}
#filled-select-container .OutLinedSelect__indicator-separator{
    background-color: #8971c3 !important;

}
#filled-select-container .css-1pndypt-Input{
    color:#fff7f7 !important;
    font-family: Rubik-Regular;
}



.OutLinedSelect-err.OutLinedSelect__indicator-separator {
    background-color: #fd5a56 !important;
    margin: 0 !important;
}

.OutLinedSelect-disabled.OutLinedSelect__indicator-separator {
    background-color: #e8e9ec !important;
    margin: 0 !important;
}
.PhoneInputInput:disabled{
    border: 2px solid #EEE9F8 !important;
    background: transparent;
    color: #2E2E2E !important;
}
.OutLinedSelect__indicator svg {
    margin-right: 5px;
}

.OutLinedSelect__indicator svg path {
    fill: rgba(76, 35, 181, 0.3) !important;
}

.OutLinedSelect-err.OutLinedSelect__indicator svg path {
    fill: #fd5a56 !important;
}

.OutLinedSelect-disabled.OutLinedSelect__indicator svg path {
    fill: #e8e9ec !important;
}

.OutLinedSelect__indicators {
    width: 65px;
    cursor: pointer;
    justify-content: space-around;
    /*border-left: 2px rgba(76, 35, 181, 0.3) !important;*/
}

.OutLinedSelect__menu {
    z-index: 1002 !important;
}

/*React-datez*/
.outlined-date-select-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.outlined-date-select-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.outlined-date-select-label-focused, .outlined-date-select-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 0.75rem;
}

.outlined-date-select {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.outlined-date-select-focused {
    border: 3px solid #4c23b5;
}

.outlined-date-select-label-err {
    color: #fd5a56 !important;
}

.outlined-date-select-label-disabled {
    color: #e8e9ec !important;
}

.outlined-date-select-err {
    border: 3px solid #fd5a56 !important;
}

/*.outlined-date-select-disabled {*/
/*    border: 3px solid #e8e9ec !important;*/
/*}*/
/*.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {*/
/*    height: calc(130px + (1.7rem / 2))!important;*/
/*}*/
.OutLinedDateSelect {
    height: 100% !important;
    width: 100%;
}

.OutLinedDateSelect-input {
    height: 100% !important;
    width: 100%;
    outline: unset !important;
    border: unset !important;
    padding: 2px 43px 2px 8px;
}

.OutLinedDateSelect .cal-icon {
    height: 22px !important;
    width: 22px !important;
}

.OutLinedDateSelect-err .cal-icon g path {
    fill: #fd5a56 !important;

}

/*Time Select*/
.outlined-time-select-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.outlined-time-select-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.outlined-time-select-label-focused, .outlined-time-select-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 16px;
}

.outlined-time-select {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.outlined-time-select-focused {
    border: 3px solid #4c23b5;
}

.outlined-time-select-label-err {
    color: #fd5a56 !important;
}

.outlined-time-select-label-disabled {
    color: #e8e9ec !important;
    background-color: transparent;
}

.outlined-time-select-err {
    border: 3px solid #fd5a56 !important;
}

.outlined-time-select-disabled {
    border: 3px solid #e8e9ec !important;
}

.OutLinedTimeSelect {
    height: 100% !important;
    width: 100%;
}

.OutLinedTimeSelect-input {
    height: 100% !important;
    width: 100%;
    outline: unset !important;
    border: unset !important;
    padding: 2px 43px 2px 8px;
}

.OutLinedTimeSelect .cal-icon {
    height: 22px !important;
    width: 22px !important;
}

.OutLinedTimeSelect-err .cal-icon g path {
    fill: #fd5a56 !important;

}

.outlined-time-select .react-datepicker-wrapper, .outlined-time-select .react-datepicker-wrapper .react-datepicker__input-container, .OutLinedTimeSelect {
    border: unset !important;
    height: 100% !important;
    width: 100% !important;
}

.OutLinedTimeSelect {
    padding: 2px 43px 2px 8px;
    border: unset !important;
    outline: unset !important;
    cursor: pointer;
}

.OutLinedTimeSelect-popper {
    position: relative !important;
    margin-top:-50px !important;
    margin-bottom: 0 !important;
    transform: translate3d(0px, 50px, 0px) !important;
    width: 100%;
}

.react-datepicker__time-list-item--disabled {
    display: none !important;
}

.react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only, .react-datepicker__triangle {
    display: none !important;
}

.react-datepicker__tab-loop, .react-datepicker-popper, .react-datepicker--time-only, .react-datepicker__time-container, .react-datepicker__time, .react-datepicker__time-box {
    width: 100% !important;
}

.react-datepicker__time-list-item {
    font-family: Rubik-Regular;
    font-weight: normal !important;
    font-size: 18px;
    color: #2e2e2e !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px !important;
    border: unset !important;
    outline: unset !important;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: rgba(76, 35, 181, 0.1) !important;


}

.outlined-error {
    font-size: 12px;
    color: #fd5a56;
    padding-left: 10px;
}


/*Input*/
.outlined-custom-input-label {
    position: absolute;
    /*font-family: Roboto;*/
    left: 0;
    background-color: white;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    color: rgba(76, 35, 181, 0.5);

}

.outlined-custom-input-label-default {
    position: absolute;
    left: 0;
    font-size: 19px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
}

.outlined-custom-input-label-focused, .outlined-custom-input-label-active {
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 16px;
}

.custom-input {
    position: relative;
    border-radius: 5px;
    height: 56px !important;
    padding: 0 12px;
    outline: none;
    transition: 0.1s ease-out;
    font-size: 19px;
    border: 3px solid rgba(76, 35, 181, 0.5);
}

.custom-input-focused {
    border: 3px solid #4c23b5;
}

.outlined-custom-input-label-err {
    color: #fd5a56 !important;
}

.outlined-custom-input-label-disabled {
    color: #e8e9ec !important;
    background-color: transparent;
}

.outlined-custom-input-label-valued-disabled {
    color: #e8e9ec !important;
    background-color: white;
    z-index: 1000;
    top: 0;
    transform: translateY(-50%);
    font-size: 0.75rem;
}

.custom-input-err {
    border: 3px solid #fd5a56 !important;
}

.custom-input-disabled {
    border: 2px solid #EEE9F8 !important;
    background: white;
    color: #2E2E2E !important;
}

.custom-input {
    height: 100% !important;
    width: 100%;
}

/*.custom-input {*/
/*    height: 100% !important;*/
/*    width: 100%;*/
/*    outline: unset !important;*/
/*    border: unset !important;*/
/*    padding: 2px 43px 2px 8px;*/
/*}*/

.custom .cal-icon {
    height: 22px !important;
    width: 22px !important;
}

.custom-err .cal-icon g path {
    fill: #fd5a56 !important;

}

.custom-input .react-datepicker-wrapper, .custom-input .react-datepicker-wrapper .react-datepicker__input-container, .custom {
    border: unset !important;
    height: 100% !important;
    width: 100% !important;
}

.custom {
    padding: 2px 43px 2px 8px;
    border: unset !important;
    outline: unset !important;
    cursor: pointer;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: rgba(76, 35, 181, 0.1) !important;


}

.outlined-error {
    font-size: 12px;
    color: #fd5a56;
    padding-left: 10px;
}
.PhoneInput{
    height: 100% !important;
}
.PhoneInputInput{
    border-radius: 5px;
    border:2px solid #EEE9F8;
    height: 100% !important;
}
/*.PhoneInputInput:disabled{*/
/*    color: hsl(0, 0%, 60%) !important;*/
/*}*/
.PhoneInputCountry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    border-radius: 5px;
    border: 2px solid #EEE9F8 !important;
    background: white;
    color: #2E2E2E !important;
}
.PhoneInputCountry select:disabled {
    opacity: 0;
}