.input-patients-design {
    width: 20rem;
    height: 3.5rem;
    flex-grow: 0;
    background-color: rgba(76, 35, 181, 0.1);
    margin-bottom: 30px;

}
.css-ssnzt1-control{
    color: #2E2E2E !important;
}
.input-patients-design-phone {
    width: 20rem;
    height: 3.5rem;
    flex-grow: 0;
    margin-bottom: 30px;

}
.input-patients-design-mobile{
    width:18.438rem !important;
}
.patient-profile-container{
    max-height: 85vh;
    min-height: 20vh;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: left;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.25rem;
    margin-top: 2rem;

}
.dtc-patient-profile{
    /*max-height: 85vh;*/
    min-height: 20vh;
    height: fit-content;
    max-height: 75vh;
    background: #FFFFFF;
    border-radius: 10px;
    overflow-y: auto;
    margin-top: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 2.25rem;
}
.patient-profile-container-mobile {
    /*max-height: 85vh;*/
    min-height: 20vh;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: left;
    padding-bottom: 1.5rem;
    margin-top:1rem;

}
.nav{
    border-bottom: 1px solid #F0EFF2;
    width:98%;
}
.tabClassName{
    font-family: Rubik-Regular ;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem ;
    line-height: 1.5rem !important;
    /* identical to box height, or 100% */

    display: flex !important;
    align-items: center !important;

    color: #666666 !important;

}
.tabClassName-selected{
    color: #7B5EC6 !important;
    border-bottom: 1px solid #7B5EC6 !important;
}

.container-radio {
    position: relative;
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding-left: 1.9rem;
    text-align: left;
    color: #343131;
    -webkit-user-select: none;
    user-select: none;
    height: 1.375rem;
    display: flex;
    align-items: center;
    margin-right: 2.063rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.375rem;
    width: 1.375rem;
    background-color:#FFFFFF;
    border-radius: 50%;
    border:1px solid #7B5EC6;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {


}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
    background-color: #FFFFFF;

}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
    top: 2px;
    right: 2px;
    width: 1rem;
    height:1rem;
    border-radius: 50%;
    background-color: #7B5EC6;
}
.questionCon{
    margin-bottom: 1.875rem;



}
.questionBorder{
    border-bottom:2px solid #F0EFF2 ;
    width: 98%;
    height: 2px;
    margin-top: 2.188rem;
    margin-bottom: 2.188rem;
}
.questionClass{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1.063rem;
    display: flex;
    align-items: center;
    color: #2E2E2E;

}
.questionNote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.75rem;
    gap: 0.75rem;
    width: 49%;
    height: 3.5rem;
    background: #F8F8F8;
    border-radius: 4px;
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2E2E2E;
    margin-top: 1.063rem;
}
.vaccination-container{
    display: grid;
    grid-template-columns: auto auto;

}
.vaccination-container-mobile{
    grid-template-columns: auto ;
}
.vaccination-radio-container{
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-bottom: 1.375rem;
}
.vaccination-radio-label{
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;
    color: #2E2E2E;
}
/*.container-radio {*/
/*    display: block;*/
/*    position: relative;*/
/*    padding-left: 35px;*/
/*    margin-bottom: 12px;*/
/*    cursor: pointer;*/
/*    font-size: 22px;*/
/*    -webkit-user-select: none;*/
/*    -moz-user-select: none;*/
/*    -ms-user-select: none;*/
/*    user-select: none;*/
/*}*/

/* Hide the browser's default checkbox */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark-checkBox {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.375rem;
    width: 1.375rem;
    background-color: #FFFFFF;
    border: 1.5px solid #7B5EC6;

}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark-checkBox {
    background-color:#FFFFFF;
}

/* When the checkbox is checked, add a blue background */
.container-radio input:checked ~ .checkmark-checkBox {
    background-color: #7B5EC6;
}

/* Create the checkmark-checkBox/indicator (hidden when not checked) */
.checkmark-checkBox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark-checkBox when checked */
.container-radio input:checked ~ .checkmark-checkBox:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-radio .checkmark-checkBox:after {
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.lastUpdated{
    font-family: Rubik-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    text-align: right;
    color: #A7A7A7;
    margin-right: 2rem;
    width: -moz-fit-content;
    width: 98%;
    position: relative;
    top: 1rem;
}